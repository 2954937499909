import { MediaServerURL } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { slugify } from '@iheartradio/web.utilities';

import { useItemSelected } from '~app/analytics/use-item-selected';
import {
  CardCarousel,
  useCarouselSlidesContext,
} from '~app/components/card-carousel';
import {
  ContentCard,
  ContentCardImage,
} from '~app/components/content-card/content-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import { Play } from '~app/playback/controls/play';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPodcastUrl } from '~app/utilities/urls';

import type { HomeLoaderData } from '../_home';

function Slide({
  pageName,
  podcast,
  index,
  loadingStrategy = 'eager',
  sectionPosition,
  section,
}: {
  pageName: string;
  podcast: HomeLoaderData['topPodcasts'][number];
  index: number;
  loadingStrategy?: HTMLImageElement['loading'];
  sectionPosition: number;
  section: string;
}) {
  const { onItemSelected } = useItemSelected();
  const isMobile = useIsMobile();

  const { playing } = Play.usePodcastPlay({
    id: Number(podcast.id),
    context: 0,
  });

  const podcastUrl = buildPodcastUrl({
    podcastId: Number(podcast.id),
    slug: 'slug' in podcast ? (podcast.slug as string) : '',
  });

  return (
    <CardCarousel.Slide key={podcast.id}>
      <ContentCard
        description={
          'description' in podcast ?
            (podcast.description as string)
          : (podcast.subtitle ?? '')
        }
        href={podcastUrl}
        image={
          <ContentCardImage
            alt={podcast.title ?? ''}
            data-test="recommended-podscast-artwork"
            decoding="auto"
            // If `index` is zero, set `fetchpriority` to 'high' to speed up FCP/LCP since this
            // carousel is 'above-the-fold'
            {...(index === 0 ? { fetchpriority: 'high' } : {})}
            loading={loadingStrategy}
            src={MediaServerURL.fromCatalog({
              type: 'podcast',
              id: podcast.id,
            }).quality(isMobile ? 40 : 50)}
            width={isMobile ? 75 : 120}
          />
        }
        imageButton={
          <Play.Podcast context={0} id={Number(podcast.id)} size={48} />
        }
        isActive={playing}
        onNavigate={() => {
          onItemSelected({
            pageName,
            section,
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${Playback.StationType.Podcast}|${podcast.id}`,
                name: podcast.title ?? '',
                type: Playback.StationType.Podcast,
              },
            },
          });
        }}
        title={podcast.title ?? ''}
      />
    </CardCarousel.Slide>
  );
}

Slide.displayName = 'RecommendedPodcastsCarouselSlide';

export function RecommendedPodcastsCarousel({
  pageName,
  podcasts,
  sectionPosition,
  title,
}: {
  pageName: string;
  podcasts: HomeLoaderData['topPodcasts'];
  sectionPosition: number;
  title: string;
}) {
  return (
    <CardCarousel kind="content" title={title}>
      <RecommendedPodcastsSlides
        pageName={pageName}
        podcasts={podcasts}
        sectionPosition={sectionPosition}
        title={title}
      />
    </CardCarousel>
  );
}

// Uses the value from `useCarouselSlidesContext`
// to determine whether to set `loading` prop (for the card image)
// to `eager` or `lazy`.
//
// We want `eager` if the slide is initally visible, so that the page will not try and render a
// potential LCP (Largest Contentful Paint) image asynchronously (which is bad for Lighthouse score)
function RecommendedPodcastsSlides({
  pageName,
  podcasts,
  sectionPosition,
  title,
}: {
  pageName: string;
  podcasts: HomeLoaderData['topPodcasts'];
  sectionPosition: number;
  title: string;
}) {
  const [initialSlides] = useCarouselSlidesContext();

  return podcasts.map((podcast, index) => (
    <Slide
      index={index}
      key={podcast.id}
      loadingStrategy={index < initialSlides ? 'eager' : 'lazy'}
      pageName={pageName}
      podcast={podcast}
      section={slugify(title)}
      sectionPosition={sectionPosition}
    />
  ));
}
