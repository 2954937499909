import {
  type Analytics,
  eventType as EventType,
} from '@iheartradio/web.analytics';
import { isNotBlank } from '@iheartradio/web.utilities';
import { useEffect, useMemo } from 'react';
import { debounce } from 'remeda';

import { useAnalytics } from './create-analytics';

// Debouncing the actual tracking function so that re-renders do not trigger more than
// one (hopefully) analytics call [DEM 2024/12/20]
const doPageView = debounce(
  (
    analytics: Analytics.Analytics,
    pageName: Analytics.PageView['pageName'],
    href: string,
    pageData?: Analytics.PageView['view'],
  ) => {
    analytics.track({
      type: EventType.enum.PageView,
      data: {
        pageName,
        ...(isNotBlank(pageData) ? { view: pageData } : {}),
        window: {
          location: {
            href,
          },
        },
      },
    });
  },
  { timing: 'trailing', waitMs: 500 },
);

export function usePageView(
  pageName: Analytics.PageView['pageName'],
  _pageData?: Analytics.PageView['view'],
) {
  const analytics = useAnalytics();

  // This complex memoization is done so that each of the constituents of `pageData` are compared
  // only at the primitive level. This memoized value is then passed in the dependency array of
  // the Effect below
  const pageData: Analytics.PageView['view'] = useMemo(
    () =>
      _pageData?.asset.id ?
        {
          asset: {
            id: _pageData.asset.id,
            name: _pageData.asset.name,
            ...(_pageData?.asset.sub?.id && _pageData.asset.sub.name ?
              {
                sub: {
                  id: _pageData.asset.sub.id,
                  name: _pageData.asset.sub.name,
                },
              }
            : {}),
          },
        }
      : undefined,
    [
      _pageData?.asset.id,
      _pageData?.asset.name,
      _pageData?.asset.sub?.id,
      _pageData?.asset.sub?.name,
    ],
  );

  useEffect(() => {
    doPageView.call(analytics, pageName, window.location.href, pageData);
  }, [analytics, pageName, pageData]);
}
