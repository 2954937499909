import type { Analytics } from '@iheartradio/web.analytics';
import { eventType } from '@iheartradio/web.analytics';
import {
  isNotBlank,
  isNull,
  isUndefined,
  waitUntil,
} from '@iheartradio/web.utilities';
import { createEmitter } from '@iheartradio/web.utilities/create-emitter';
import {
  type Logger,
  createLogger,
  Level,
} from '@iheartradio/web.utilities/create-logger';
import {
  type ReactElement,
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { debounce } from 'remeda';

import {
  type HeaderBiddingConfig,
  createHeaderBidding,
} from '../../header-bidding/index.js';
import { createGooglePublisherTag } from '../gpt/index.js';

type DisplayAdClick = Extract<
  Analytics.Event,
  { type: typeof eventType.enum.DisplayAdClick }
>;

type DisplayAdError = Extract<
  Analytics.Event,
  { type: typeof eventType.enum.DisplayAdError }
>;

export const DisplayAdsContext = createContext({ enabled: false });

export const createSlotRenderEndedHandler = ({
  logger,
}: {
  logger: Logger;
}) => {
  return (event: googletag.events.SlotRenderEndedEvent) => {
    const elementId = event.slot.getSlotElementId();
    const containerElement = globalThis.document.querySelector<HTMLDivElement>(
      `#${elementId}`,
    );
    logger.info('slot render ended');

    const adIframe = containerElement?.querySelector('iframe');
    if (
      adIframe &&
      (adIframe.dataset.loadComplete === 'true' ||
        adIframe.dataset.isSafeframe === 'true') &&
      !isNull(containerElement)
    ) {
      containerElement.style.opacity = '1';
      const wrapper = containerElement.parentElement;
      if (wrapper) {
        wrapper.style.opacity = '1';
      }
    }
    return waitUntil(
      () =>
        adIframe?.dataset.loadComplete === 'true' ||
        adIframe?.dataset.isSafeframe === 'true',
      500,
    );
  };
};

const { call: doAdImpression } = debounce(
  async (
    track: Analytics.Analytics['track'],
    event: googletag.events.SlotOnloadEvent,
  ) => {
    const { slot: googleSlot, serviceName: googleServiceName } = event;
    const size = googleSlot.getSizes()?.[0];

    return track({
      type: eventType.enum.DisplayAdImpression,
      data: {
        ad: {
          advertiserId: googleSlot?.getResponseInformation()?.advertiserId ?? 0,
          targeting: googleSlot?.getTargetingMap(),
          campaignId: googleSlot?.getResponseInformation()?.campaignId ?? 0,
          clickthroughUrl: googleSlot?.getClickUrl(),
          client: 'googima',
          creativeId: googleSlot?.getResponseInformation()?.creativeId ?? 0,
          lineItemId: googleSlot?.getResponseInformation()?.lineItemId ?? 0,
          position: googleSlot?.getSlotElementId(),
          serviceName: googleServiceName,
          size: {
            width:
              typeof size === 'object' && 'width' in size ? size['width'] : 0,
            height:
              typeof size === 'object' && 'height' in size ? size['height'] : 0,
          },
          tag: googleSlot?.getContentUrl()?.toString(),
          viewable:
            globalThis.window?.document?.visibilityState === 'visible' ? 1 : 0,
        },
      },
    });
  },
  { timing: 'trailing', waitMs: 1000 },
);

export const createGoogleAds = ({
  analytics,
  logger = createLogger({
    enabled: false,
    level: Level.Info,
    namespace: 'web.ads',
    pretty: true,
  }),
}: {
  analytics: Analytics.Analytics;
  logger?: Logger;
}) => {
  if (isNotBlank(globalThis.window?.location)) {
    const locationUrl = new URL(globalThis.window.location.href);
    const debugValue = locationUrl.searchParams.get('debug');

    if (debugValue === 'true' || debugValue === 'ads') {
      logger.enable();
    }
  }
  const GPT = createGooglePublisherTag({ logger });
  const HeaderBidding = createHeaderBidding({ logger });

  const slotRenderEndedEventHandler = createSlotRenderEndedHandler({
    logger,
  });

  const errorHandler = (error: Error, rest: Record<string, unknown>) => {
    logger.error('GoogleAds error', { error });
    analytics.track<DisplayAdError>({
      type: eventType.enum.DisplayAdError,
      data: {
        ad: {
          error: error.message,
          ...rest,
        },
      },
    });
  };

  const slotOnloadEventHandler = (event: googletag.events.SlotOnloadEvent) => {
    const { slot: googleSlot } = event;
    doAdImpression(analytics.track, event);

    logger.info('slot onload', { googleSlot });
  };

  const targeting = new Map<string, string | string[]>();

  const GoogleAds = createEmitter({
    async initialize(headerBiddingConfig: HeaderBiddingConfig) {
      await GPT.initialize();
      await HeaderBidding.initialize(headerBiddingConfig);
      logger.info('Google Ads Initialized');
    },
    ready() {
      return GPT.pubadsReady();
    },
    async addSlot(
      adUnitPath: string,
      size: googletag.GeneralSize,
      div: string,
      slotTargeting?: Record<string, string | string[]>,
    ) {
      const slot = await GPT.defineSlot(adUnitPath, size, div);

      if (slot) {
        logger.info('Slot Added', slot);
        if (isNotBlank(slotTargeting) && !isUndefined(slotTargeting)) {
          for (const [key, value] of Object.entries(slotTargeting!)) {
            slot.setTargeting(key, value);
          }
          logger.info('Targeting added for slot', { slot, slotTargeting });
        }
      } else {
        errorHandler(new Error('Slot not added'), { position: div });
      }
    },
    async removeSlot(div: string) {
      const slot = (await GPT.getSlots()).find(
        slot => slot.getSlotElementId() === div,
      );
      if (slot) {
        await GPT.destroySlots([slot]);
        logger.info('Slot removed', { slot });
      }
    },
    async receiveTargeting(targetingParams: { [k: string]: unknown }) {
      targeting.clear();
      for (const [key, value] of Object.entries(targetingParams)) {
        if (key === 'childDirected') {
          logger.info(
            `Setting privacy settings, childDirectedTreatment: ${String(
              value,
            )}`,
          );
          await (typeof value === 'boolean' && value ?
            GPT.setPrivacySettings({ childDirectedTreatment: true })
          : GPT.setPrivacySettings({ childDirectedTreatment: false }));
        } else {
          targeting.set(key, String(value));
        }
      }
      // Move these out of the targeting hook, because they should really only should be set when
      // the request is made

      targeting.set('ts', String(Date.now()));
      targeting.set('ord', String(Math.floor(Math.random() * Date.now())));

      logger.info('Setting targeting', { targeting });
    },
    async refresh() {
      const containers = ((await GPT.getSlots()) ?? []).map(slot =>
        slot.getSlotElementId(),
      );
      for (const container of containers) {
        const containerElement =
          globalThis.window.document.querySelector<HTMLDivElement>(
            `#${container}`,
          );
        if (!isNull(containerElement)) {
          containerElement.style.opacity = '0';
        }
      }
      if (containers.length > 0) {
        logger.info('Refreshing slots');
        GPT.clearTargeting();
        for (const [key, value] of targeting.entries()) {
          GPT.setTargeting(key, value);
        }
        await HeaderBidding.fetchBids(await GPT.getSlots());
        GPT.refresh();
      } else {
        logger.warn('No slots to refresh');
      }
    },
    async start() {
      if (globalThis.window) {
        logger.info('GoogleAds.start()');

        GPT.enableLazyLoad({
          // The minimum distance from the current viewport a slot. A value of 0 means "when the slot enters the viewport"
          fetchMarginPercent: 0,
          // The minimum distance from the current viewport a slot must be before we render an ad
          renderMarginPercent: 0,
          mobileScaling: 1,
        });
        GPT.disableInitialLoad();
        GPT.enableSingleRequest();
        GPT.setPrivacySettings({ restrictDataProcessing: true });
        GPT.enableServices();

        GPT.addEventListener<'slotRenderEnded'>(
          'slotRenderEnded',
          slotRenderEndedEventHandler,
        );

        GPT.addEventListener<'slotOnload'>(
          'slotOnload',
          slotOnloadEventHandler,
        );

        await waitUntil(() => GPT.pubadsReady());

        GoogleAds.refresh();
      }
    },
    async stop() {
      logger.info('GoogleAds.stop()');
      GPT.removeEventListener<'slotRenderEnded'>(
        'slotRenderEnded',
        slotRenderEndedEventHandler,
      );
      GPT.removeEventListener<'slotOnload'>(
        'slotOnload',
        slotOnloadEventHandler,
      );
    },
    async visibilityChange() {
      const state = globalThis.window?.document?.visibilityState;
      logger.info(`Document visibility changed to ${state}`);
      if (state === 'visible') {
        logger.info('Document visible, refreshing ads...');
        GoogleAds.refresh();
      }
    },
    async click(event: MouseEvent) {
      const googleSlot = (await GPT.getSlots())?.find(
        slot => slot.getSlotElementId() === (event.target as HTMLDivElement).id,
      );

      if (!googleSlot) {
        return;
      }

      const googleServiceName = googleSlot.getServices().at(0)?.getName();
      const size = googleSlot.getSizes().at(0);

      analytics.track<DisplayAdClick>({
        type: eventType.enum.DisplayAdClick,
        data: {
          ad: {
            advertiserId:
              googleSlot.getResponseInformation()?.advertiserId ?? 0,
            targeting: googleSlot.getTargetingMap(),
            campaignId: googleSlot.getResponseInformation()?.campaignId ?? 0,
            clickthroughUrl: googleSlot.getClickUrl(),
            client: 'googima',
            creativeId: googleSlot?.getResponseInformation()?.creativeId ?? 0,
            lineItemId: googleSlot?.getResponseInformation()?.lineItemId ?? 0,
            position: googleSlot?.getSlotElementId(),
            serviceName: googleServiceName ?? '',
            size: {
              width:
                typeof size === 'object' && 'width' in size ? size['width'] : 0,
              height:
                typeof size === 'object' && 'height' in size ?
                  size['height']
                : 0,
            },
            tag: googleSlot?.getContentUrl()?.toString(),
            viewable:
              globalThis.window?.document?.visibilityState === 'visible' ?
                1
              : 0,
          },
        },
      });
    },
  });

  let refreshTimer: number | undefined;

  GoogleAds.subscribe({
    catch(_, error, ...args) {
      logger.info('GoogleAds exception', { error, args });
      GPT.getSlots().then(slots => {
        for (const slot of slots) {
          errorHandler(error, { position: slot.getSlotElementId() });
        }
      });
    },

    addSlot() {
      logger.info('GoogleAds.addSlot', { ts: performance.now() });
      if (refreshTimer) {
        window.clearTimeout(refreshTimer);
      }
      refreshTimer = window.setTimeout(() => {
        GoogleAds.refresh();
        refreshTimer = undefined;
      }, 500);
    },
  });

  return {
    GoogleAds,
    slotOnloadEventHandler,
    GoogleAdsProvider: ({
      children,
      headerBiddingConfig,
      enabled = true,
    }: {
      children: ReactNode;
      headerBiddingConfig: HeaderBiddingConfig;
      enabled: boolean;
    }): ReactElement => {
      useMemo(() => {
        if (
          !isUndefined(globalThis.window) &&
          !GoogleAds.initialized &&
          enabled
        ) {
          logger.info('Ads enabled, initializing GoogleAds');
          GoogleAds.initialize(headerBiddingConfig);
        } else {
          logger.info('Ads not enabled, skipping GoogleAds.initialize');
        }
      }, [headerBiddingConfig, enabled]);

      useEffect(() => {
        if (enabled) {
          logger.info('Ads enabled, starting GoogleAds');
          globalThis.window.addEventListener(
            'visibilitychange',
            GoogleAds.visibilityChange,
          );
          GoogleAds.start();

          return () => {
            GoogleAds.stop();
            globalThis.window.removeEventListener(
              'visibilitychange',
              GoogleAds.visibilityChange,
            );
          };
        } else {
          logger.info('Ads not enabled, skipping GoogleAds.start');
        }
      }, [enabled]);

      return (
        <DisplayAdsContext.Provider
          value={useMemo(() => ({ enabled }), [enabled])}
        >
          {children}
        </DisplayAdsContext.Provider>
      );
    },
    useDisplayAdsContext: () => {
      return useContext(DisplayAdsContext);
    },
  };
};
