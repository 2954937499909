import { createStateContext } from '@iheartradio/web.remix-shared/react/create-state-context.js';
import { useCallback } from 'react';

/**
 * This file creates a scoped context for every Select/Combobox > Carousel/Grid pattern
 * that allows clicks to be disabled on the cards/tiles while the Select/Combobox is open.
 *
 * This allows us to do away with the 1000ms wait to close the select for mobile and makes the
 * app feel more responsive.
 */

const { Provider: SelectInteractionProvider, useContext } = createStateContext(
  'SelectInteraction',
  false,
);

const useSelectInteraction = (): [boolean, (isOpen: boolean) => void] => {
  const [isOpen, _setIsOpen] = useContext();
  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      globalThis.setTimeout(() => {
        _setIsOpen(isOpen);
      }, 0);
    },
    [_setIsOpen],
  );
  return [isOpen, setIsOpen];
};

export { SelectInteractionProvider, useSelectInteraction };
