import { createStateContext } from '@iheartradio/web.remix-shared/react/create-state-context.js';

/**
 * This provider and hook are to be used for determining whether or not a browser is "mobile", the
 * value for which comes from the Fastly layer. It is passed from Fastly to the RadioEdit
 * application via a header, and then it is passed into the app context in the Hono layer for the
 * Remix application.
 *
 * This allows us to do things like:
 * - conditionally size images
 * - only load/execute AppsFlyer for mobile browsers
 * - ... other stuff I haven't even thought of yet
 */
const { Provider: IsMobileProvider, useContext } = createStateContext(
  'IsMobile',
  false,
);

const useIsMobile = () => {
  const [isMobile] = useContext();
  return isMobile;
};

export { IsMobileProvider, useIsMobile };
