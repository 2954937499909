import type { SliderProps } from '@iheartradio/web.accomplice/slider';
import { Slider } from '@iheartradio/web.accomplice/slider';
import {
  Badge,
  Box,
  Flex,
  media,
  Player,
  Text,
  useDebounceCallback,
  useMediaQuery,
} from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { toHHMMSS } from '@iheartradio/web.utilities';

import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { playback } from '../playback';

export function Time() {
  const [isFullScreen] = Player.useFullScreen();
  const isLarge = useMediaQuery(media.large);
  const player = playback.usePlayer();
  const { adBreak } = playback.useAds();
  const time = playback.useTime();
  const isPremium = isPremiumUser(useUser());
  const { station, status } = playback.useState();
  const isLiveStation = station?.type === Playback.StationType.Live;
  const isPodcast = station?.type === Playback.StationType.Podcast;

  const seek = useDebounceCallback(
    (value: number) => {
      player.seek(value);
    },
    500,
    { leading: true },
  );

  // If you are an AA account, or content is a podcast - you can scrub progress bar on FSP and large+ breakpoints,
  // Otherwise (any other user type), you can not scrub,
  // Regardless, the spec is that any user can not scrub progress bar on mobile breakpoints due to the design, justifying to the use of `isLarge`.
  const position = isLiveStation && !adBreak ? 0 : time.position;

  return (
    <PlayerTime
      // Position is the placement of the scrub thumb on the progress bar,
      // since live stations don't have a progress bar, we set position to 0
      badge={isLiveStation && !adBreak ? Playback.StationType.Live : undefined}
      duration={time.duration}
      // Only show LIVE badge on live stations (this replaces the player progress bar in the UI)
      interactive={isPremium || isPodcast ? isFullScreen || isLarge : false}
      // We only disable progress bar if it's a live station or if playback is not playing or paused
      isDisabled={
        (isLiveStation && !adBreak) || status === Playback.Status.Buffering
      }
      onChange={seek}
      position={position}
    />
  );
}

type PlayerTimeProps = {
  isDisabled?: SliderProps['isDisabled'];
  badge?: string;
  onChange?: SliderProps['onChange'];
  position: number;
  duration: number;
  interactive?: boolean;
};

export function PlayerTime({
  badge,
  position,
  isDisabled = false,
  duration,
  interactive,
  onChange,
  ...props
}: PlayerTimeProps) {
  return (
    <Flex
      css={{
        height: 'initial',
        padding: '$0',

        [`@container ${media.large}`]: {
          padding: '$0 $16',
          height: '2.7rem',
          display: 'flex',
        },
        [`@container ${media.xlarge}`]: {
          padding: '$0 $32',
        },

        '[data-player-type="fullscreen"] &': {
          height: '2.7rem',
          padding: '$0 $16',

          [`@container ${media.large}`]: {
            display: 'none',
          },
        },
      }}
      gridArea="time"
      justifyContent="center"
      position="relative"
      width="100%"
      {...props}
    >
      <Flex
        alignItems="center"
        columnGap="$16"
        css={{
          transform: 'translateY(-1.4rem)',
          width: '100%',
          position: 'absolute',

          [`@container ${media.large}`]: {
            transform: 'translateY(0rem)',
            width: '30rem',
            position: 'relative',
          },
          [`@container ${media.xlarge}`]: { width: '60rem' },

          '[data-player-type="fullscreen"] &': {
            transform: 'translateY(0rem)',

            [`@container ${media.small}`]: { width: '80%' },
            [`@container ${media.medium}`]: { width: '70%' },
          },
        }}
        justifyContent="center"
        justifySelf="center"
      >
        {!badge && position > 0 && (
          <Text
            aria-label="position"
            css={{
              display: 'none',

              [`@container ${media.large}`]: {
                display: 'block',
              },

              '[data-player-type="fullscreen"] &': {
                display: 'block',

                [`@container ${media.large}`]: {
                  display: 'none',
                },
              },
            }}
            kind="caption-4"
            role="timer"
          >
            {toHHMMSS(position)}
          </Text>
        )}
        <Box
          css={{
            display: badge ? 'none' : 'contents',
            '[data-player-type="fullscreen"] &': {
              display: 'contents',
            },
          }}
        >
          <Slider
            aria-label="Adjust Playback Progress"
            data-test="progress-bar-player-control"
            interactive={interactive}
            isDisabled={isDisabled}
            maxValue={duration}
            minValue={0}
            onChangeEnd={onChange}
            step={1}
            value={badge ? 0 : position}
          />
        </Box>
        {!badge && position > 0 && (
          <Text
            aria-label="duration"
            css={{
              display: 'none',

              [`@container ${media.large}`]: {
                display: 'block',
              },

              '[data-player-type="fullscreen"] &': {
                display: 'block',

                [`@container ${media.large}`]: {
                  display: 'none',
                },
              },
            }}
            kind="caption-4"
            role="timer"
          >
            {toHHMMSS(duration)}
          </Text>
        )}
        <Badge
          backgroundColor="$brand-red"
          color="$brand-white"
          css={{
            [`@container ${media.xsmall}`]: { display: 'none' },
            [`@container ${media.large}`]: { display: 'block' },
            '[data-player-type="fullscreen"] &': { display: 'block' },
          }}
          placeSelf="center"
          position="absolute"
          size="small"
        >
          {badge}
        </Badge>
      </Flex>
    </Flex>
  );
}

PlayerTime.displayName = 'Player.Time';
