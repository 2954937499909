import { MediaServerURL } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { slugify } from '@iheartradio/web.utilities';
import { isEmpty } from 'remeda';

import { useItemSelected } from '~app/analytics/use-item-selected';
import type { FeaturedPodcastsResult } from '~app/api/types';
import {
  CardCarousel,
  useCarouselSlidesContext,
} from '~app/components/card-carousel';
import {
  FeatureCard,
  FeatureCardImage,
} from '~app/components/feature-card/feature-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import { useGetImageDominantColor } from '~app/hooks/use-get-image-dominant-color';
import { Play } from '~app/playback/controls/play';
import { AnalyticsContext } from '~app/utilities/constants';
import { getPodcastSlugFromWebLink } from '~app/utilities/slugs/get-podcast-profile-slug';
import { buildPodcastUrl } from '~app/utilities/urls';

const sectionTitle = 'Our Podcast Picks';

export const getPodcastHref = (
  podcast?: FeaturedPodcastsResult[number] | null,
) => {
  if (!podcast || isEmpty(podcast)) {
    return;
  }
  const slug = getPodcastSlugFromWebLink(podcast?.link?.urls?.web);
  return buildPodcastUrl({
    podcastId: podcast?.catalog?.id ?? '',
    slug,
  });
};

export function Slide({
  index,
  loadingStrategy = 'eager',
  podcast,
  pageName,
  sectionPosition,
}: {
  index: number;
  loadingStrategy?: HTMLImageElement['loading'];
  podcast: FeaturedPodcastsResult[number];
  pageName: string;
  sectionPosition: number;
}) {
  const isMobile = useIsMobile();
  const { onItemSelected } = useItemSelected();

  const { playing } = Play.usePodcastPlay({
    context: 0,
    id: Number(podcast?.catalog?.id),
  });

  return (
    <CardCarousel.Slide key={podcast.id ?? podcast?.catalog?.id}>
      <FeatureCard
        data-test="podcast-feature-card"
        description={podcast.subtitle}
        href={getPodcastHref(podcast)}
        image={
          <FeatureCardImage
            alt={`Image for podcast: ${podcast.title}`}
            decoding="auto"
            loading={loadingStrategy}
            src={MediaServerURL.fromCatalog({
              type: 'podcast',
              id: String(podcast.catalog?.id),
            }).quality(isMobile ? 40 : 75)}
            width={isMobile ? 60 : 120}
          />
        }
        imageButton={
          <Play.Podcast
            context={0}
            id={Number(podcast?.catalog?.id)}
            size={48}
          />
        }
        isActive={playing}
        onNavigate={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${Playback.StationType.Podcast}|${podcast?.catalog?.id}`,
                name: podcast?.title ?? '',
              },
            },
          });
        }}
        title={podcast.title || undefined}
      />
    </CardCarousel.Slide>
  );
}

export function FeaturedPodcastsCarousel({
  podcasts,
  pageName,
  sectionPosition,
}: {
  podcasts: FeaturedPodcastsResult | [];
  color?: string;
  pageName: string;
  sectionPosition: number;
}) {
  const carouselBackgroundColor = useGetImageDominantColor(
    podcasts?.[0]?.img_uri,
  );

  if (podcasts.length === 0) {
    return null;
  }

  return (
    <CardCarousel
      color={carouselBackgroundColor}
      kind="featured"
      title={sectionTitle}
    >
      <FeaturedPodcastsSlides
        pageName={pageName}
        podcasts={podcasts}
        sectionPosition={sectionPosition}
      />
    </CardCarousel>
  );
}

function FeaturedPodcastsSlides({
  podcasts,
  pageName,
  sectionPosition,
}: {
  podcasts: FeaturedPodcastsResult;
  pageName: string;
  sectionPosition: number;
}) {
  const [initialSlides] = useCarouselSlidesContext();

  return podcasts.map((podcast, index) => (
    <Slide
      index={index}
      key={podcast.id}
      loadingStrategy={index < initialSlides ? 'eager' : 'lazy'}
      pageName={pageName}
      podcast={podcast}
      sectionPosition={sectionPosition}
    />
  ));
}
