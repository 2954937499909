import { useEffect, useState } from 'react';
import { isNullish } from 'remeda';

export const useGetImageDominantColor = (
  src?: string | null,
  color?: string,
) => {
  const [dominantColor, setDominantColor] = useState<string | undefined>(color);

  useEffect(() => {
    const abortController = new AbortController();

    if (!isNullish(src) && isNullish(color)) {
      const imageUrl = encodeURIComponent(src);

      fetch(`/api/v1/image/meta/${imageUrl}`, {
        signal: abortController.signal,
      })
        .then(async response => {
          if (response.ok) {
            const { color } = await response.json();
            setDominantColor(color);
            return true;
          }
          return false;
        })
        .catch(() => {});
    } else if (!isNullish(color)) {
      setDominantColor(color);
    }

    return () => {
      setDominantColor(undefined);
      abortController.abort();
    };
  }, [setDominantColor, src, color]);

  return dominantColor;
};
