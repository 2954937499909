import { lightDark, vars } from '@iheartradio/web.accomplice';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Text } from '@iheartradio/web.accomplice/text';
import type { CarouselProps } from '@iheartradio/web.companion';
import { Box, Carousel } from '@iheartradio/web.companion';
import { createStateContext } from '@iheartradio/web.remix-shared/react/create-state-context.js';
import { type ReactNode, memo, useMemo } from 'react';

export type CardCarouselKind =
  | 'content'
  | 'featured'
  | 'news'
  | 'row'
  | 'ranker';

export type CardCarouselProps = CarouselProps & {
  color?: string;
  description?: ReactNode;
  kind: CardCarouselKind;
  hasAds?: boolean;
  isEmpty?: boolean;
};

const getSlidesToShowMap = ({
  description,
}: {
  description?: ReactNode;
}): Record<CardCarouselKind, CarouselProps['slidesToShow']> => {
  return {
    content: {
      '@initial': 2,
      '@small': 3,
      '@medium': description ? 3 : 4,
      '@large': description ? 3 : 5,
      '@xlarge': description ? 5 : 7,
    },
    featured: {
      '@initial': 1,
      '@small': 1,
      '@shmedium': 2,
      '@medium': 2,
      '@large': 2,
      '@xlarge': 3,
    },
    ranker: {
      '@initial': 2,
      '@small': 3,
      '@medium': 4,
      '@xlarge': 5,
      '@xxlarge': 6,
    },
    news: {
      '@initial': 1,
      '@small': 2,
      '@medium': 3,
      '@large': 3,
      '@xlarge': 5,
    },
    row: {
      '@initial': 1,
      '@small': 1,
      '@medium': 2,
      '@large': 1,
      '@xlarge': 2,
    },
  };
};

const GradientBackground = memo(function GradientBackground({
  color,
}: {
  color: string;
}) {
  const isTransparent = useMemo(() => color === 'transparent', [color]);
  return (
    <Box
      background={`linear-gradient(0.25turn, transparent, ${color} 50%, transparent)`}
      bottom="$0"
      opacity={{
        light: isTransparent ? '0' : '0.25',
        dark: isTransparent ? '0' : '0.35',
      }}
      position="absolute"
      top="$0"
      transition="opacity .5s linear"
      width="100%"
      zIndex="-1"
    />
  );
});

// Context and `use` hook to store the max number of initial visible slides for a carousel.
// This is so that when the slides are rendered, the `loading` prop of the image can be set to
// `eager` for slides that are initially visible, and `lazy` for slides that are outside the viewport.
//
// This is to help with LCP (Largest Contentful Paint) score, where lazily loading images negatively
// impacts that metric.
export const CarouselSlidesContext = createStateContext(
  'CarouselSlidesContext',
  0,
);
export const useCarouselSlidesContext = CarouselSlidesContext.useContext;

export const EmptyContent = () => (
  <Flex
    alignItems="center"
    height={{ mobile: '18rem', large: '21rem' }}
    justifyContent="center"
  >
    <Text
      as="p"
      color={lightDark(vars.color.gray600, vars.color.gray300)}
      kind="body-3"
    >
      No results currently available for this filter
    </Text>
  </Flex>
);

export const CardCarousel = ({
  children,
  color = 'transparent', // defaulting to 'transparent' helps avoid some significant layout shift
  description,
  kind,
  title,
  isEmpty = false,
  ...props
}: CardCarouselProps) => {
  const slidesToShowMap = useMemo(
    () => getSlidesToShowMap({ description }),
    [description],
  );

  const slidesToShow = slidesToShowMap[kind];
  // Getting the max number of slides for all breakpoints, as we need to set `loading="eager"` for
  // the max number of slides *possible*, since we will not know the breakpoint during SSR.
  const maxSlides: number =
    slidesToShow ?
      Object.values(slidesToShow).reduce(
        (accumulator, currentValue) =>
          currentValue > accumulator ? currentValue : accumulator,
        0,
      )
    : 0;

  return (
    <CarouselSlidesContext.Provider
      value={useMemo(() => maxSlides, [maxSlides])}
    >
      <Box
        data-test="card-carousel"
        padding="$16 0"
        position="relative"
        width="100%"
        zIndex="$0"
      >
        <GradientBackground color={color} />

        {description ?
          <Flex
            alignItems={{ mobile: 'left', medium: 'center' }}
            data-test="featured-layout"
            direction={{ mobile: 'column', medium: 'row' }}
          >
            <Flex
              alignItems="left"
              direction="column"
              gap="$8"
              minWidth={{
                medium: '$5',
                large: '$3',
                xlarge: '$3',
              }}
              padding={{ mobile: '$0 $16', large: '$0 $0 $0 $32' }}
            >
              <Text
                as="h3"
                css={{ width: '100%' }}
                kind={{ mobile: 'h4', large: 'h3' }}
              >
                {title}
              </Text>
              <Text as="p" kind={{ mobile: 'body-4', large: 'body-3' }}>
                {description}
              </Text>
            </Flex>
            <Box overflow="hidden">
              <Carousel
                emptyContent={isEmpty ? <EmptyContent /> : null}
                slidesToShow={slidesToShow}
                {...props}
              >
                {children}
              </Carousel>
            </Box>
          </Flex>
        : <Carousel
            emptyContent={isEmpty ? <EmptyContent /> : null}
            slidesToShow={slidesToShow}
            title={title}
            {...props}
          >
            {children}
          </Carousel>
        }
      </Box>
    </CarouselSlidesContext.Provider>
  );
};

CardCarousel.displayName = 'CardCarousel';

export const Slide = Carousel.Slide;
