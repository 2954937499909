import { Box } from '@iheartradio/web.accomplice/box';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Browse, Home, Library } from '@iheartradio/web.accomplice/icons';
import { Link } from '@iheartradio/web.accomplice/link';
import { LogotypeBeta } from '@iheartradio/web.accomplice/logos';
import {
  Navigation,
  NavigationHeader,
  NavigationItem,
  NavigationList,
  NavigationLogo,
} from '@iheartradio/web.accomplice/navigation';
import { Separator } from '@iheartradio/web.accomplice/separator';
import { Spacer } from '@iheartradio/web.accomplice/spacer';
import { NavLink } from '@remix-run/react';
import { $path } from 'remix-routes';

import { NavAd } from '~app/ads/display/nav-ad';
import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import { useUser } from '~app/contexts/user';
import type { AppsFlyerOffset } from '~app/hooks/apps-flyer/use-apps-flyer';
import { REG_GATE_TRIGGER_TYPES } from '~app/utilities/constants';

import { LoginSettingsNavItem } from './login-settings-item';

interface NavProps {
  appsFlyerOffset: AppsFlyerOffset;
}

export function Nav({ appsFlyerOffset }: NavProps) {
  const user = useUser();
  const { onAnalyticsRegGateOpen } = useRegGateEvent();

  return (
    <Box asChild data-test="nav-box" zIndex="$2">
      <Navigation
        data-has-button
        data-test="navigation-wrapper"
        key={`${user?.isAnonymous ? 'anon' : 'auth'}-nav`}
      >
        <Box asChild marginTop={appsFlyerOffset}>
          <NavigationHeader>
            <Link asChild>
              <NavLink to={$path('/')}>
                <LogotypeBeta
                  aria-label="iHeart Header Logotype"
                  media="mobile"
                  size={24}
                />
              </NavLink>
            </Link>
          </NavigationHeader>
        </Box>
        <Flex
          flexDirection="column"
          height="100%"
          justifyContent="flex-start"
          width="100%"
        >
          <NavigationLogo>
            <Link asChild>
              <NavLink to={$path('/')}>
                <LogotypeBeta
                  aria-label="iHeart Navigation Logotype"
                  media="desktop"
                  size={24}
                />
              </NavLink>
            </Link>
          </NavigationLogo>
          <Flex direction="column" height="100%" justifyContent="space-between">
            <Flex
              direction="column"
              id="user-education"
              justifyContent="flex-start"
            >
              <NavigationList>
                <NavigationItem asChild>
                  <NavLink data-test="home" end to={$path('/')}>
                    <Home />
                    Home
                  </NavLink>
                </NavigationItem>
                <NavigationItem asChild>
                  <NavLink
                    data-test="browse"
                    id="user-education-browse"
                    to={$path('/browse')}
                  >
                    <Browse />
                    Browse
                  </NavLink>
                </NavigationItem>
                <NavigationItem asChild>
                  <NavLink
                    data-test="library"
                    id="user-education-library"
                    to={$path('/library')}
                  >
                    <Library />
                    Library
                  </NavLink>
                </NavigationItem>
                <Separator
                  display={{ mobile: 'none', large: 'inline-block' }}
                />
                <LoginSettingsNavItem
                  onClick={() =>
                    onAnalyticsRegGateOpen(REG_GATE_TRIGGER_TYPES.LOG_IN)
                  }
                  user={user}
                />
              </NavigationList>
            </Flex>
            <Spacer bottom="$24" left="$8">
              <NavAd />
            </Spacer>
          </Flex>
        </Flex>
      </Navigation>
    </Box>
  );
}
