import { type ReactNode, useContext, useMemo } from 'react';
import {
  type Key,
  type ListBoxItemProps,
  type SelectProps as _SelectProps,
  ListBox,
  SelectStateContext,
} from 'react-aria-components';
import { isFunction } from 'remeda';

import type { CSSProperties } from '../../stitches.config.js';
import { type FieldProps, Field } from '../field/index.js';
import { CheckIcon } from '../icons/check-icon.js';
import { ChevronDownIcon } from '../icons/chevron-down-icon.js';
import { ChevronUpIcon } from '../icons/chevron-up-icon.js';
import { useInputProps } from '../input/index.js';
import {
  listBoxStyles,
  StyledButton,
  StyledListBoxItem,
  StyledPopover,
  StyledSelect,
  StyledSelectValue,
} from './select.primitive.js';

export type { Key };

export interface DefaultSelectOption {
  key: string;
  label: string;
  value: string;
}

export interface SelectProps<T extends object = DefaultSelectOption>
  extends Omit<_SelectProps<T>, 'children' | 'name'>,
    Omit<FieldProps, 'css'> {
  css?: CSSProperties;
  items?: Iterable<T>;
  kind?: 'flushed' | 'flushed-inline' | 'outline' | 'filled';
  name: string;
  children: ReactNode | ((item: T) => ReactNode);
}

function SelectButtonArrow({
  isDisabled,
}: {
  isDisabled: boolean | undefined;
}) {
  const state = useContext(SelectStateContext);

  const fill = useMemo(
    () =>
      ({
        dark: isDisabled ? 'gray-400' : 'gray-250',
        light: isDisabled ? 'gray-300' : 'gray-450',
      }) as const,
    [isDisabled],
  );

  return state?.isOpen ?
      <ChevronUpIcon data-label="chevron-up" fill={fill} size="18" />
    : <ChevronDownIcon data-label="chevron-down" fill={fill} size="18" />;
}

export function Select<T extends object = DefaultSelectOption>(
  props: SelectProps<T>,
) {
  const { items, children, isDisabled, kind, ...restProps } = props;
  const { fieldProps, inputProps } = useInputProps(props);
  const { disabled } = fieldProps;
  const { id } = inputProps;

  return (
    <Field {...fieldProps}>
      <StyledSelect
        id={id}
        isDisabled={disabled ?? isDisabled}
        kind={kind ?? 'flushed'}
        {...restProps}
      >
        <StyledButton>
          <StyledSelectValue />
          <SelectButtonArrow isDisabled={isDisabled} />
        </StyledButton>
        <StyledPopover>
          <ListBox className={listBoxStyles()} items={items}>
            {children}
          </ListBox>
        </StyledPopover>
      </StyledSelect>
    </Field>
  );
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface SelectOptionProps<T extends object = DefaultSelectOption>
  extends ListBoxItemProps<T> {}

export function SelectOption({ children, ...props }: SelectOptionProps) {
  return (
    <StyledListBoxItem {...props}>
      {renderProps => {
        const { isSelected } = renderProps;
        return (
          <>
            {isFunction(children) ? children(renderProps) : children}
            {isSelected ?
              <CheckIcon
                css={{
                  display: 'inline-block',
                  dark: { fill: 'gray-250' },
                  light: { fill: 'gray-450' },
                }}
                size={18}
              />
            : null}
          </>
        );
      }}
    </StyledListBoxItem>
  );
}

Select.Option = SelectOption;
