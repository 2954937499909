import { sprinkles } from '@iheartradio/web.accomplice';
import type {
  CardImageProps,
  CardPreviewProps,
  CardProps,
  CardTitleProps,
} from '@iheartradio/web.accomplice/card';
import {
  Card,
  CardBody,
  CardImage,
  CardPreview,
  CardPreviewOverlayButtonContainer,
  CardSubtitle,
  CardTitle,
} from '@iheartradio/web.accomplice/card';
import { Group } from '@iheartradio/web.accomplice/group';
import { Link } from '@iheartradio/web.accomplice/link';
import { useNavigate } from '@remix-run/react';
import { type ReactNode, useCallback, useRef, useTransition } from 'react';
import { isNonNullish, isNullish } from 'remeda';
import type { SetOptional } from 'type-fest';

export function ContentCardImage({
  src,
  ...props
}: SetOptional<CardImageProps, 'src'>) {
  if (isNullish(src)) {
    return null;
  }

  return (
    <CardImage {...props} aspectRatio="1 / 1" placeholder={false} src={src} />
  );
}

export type ContentCardProps = Omit<CardProps, 'onPress'> & {
  actions?: ReactNode;
  description?: ReactNode;
  href?: string;
  image?: ReactNode;
  imageButton?: ReactNode;
  linesForTitle?: CardTitleProps['lines'];
  onNavigate?: () => void;
  previewShape?: CardPreviewProps['shape'];
  title?: ReactNode;
};

export function ContentCard(props: ContentCardProps) {
  const navigate = useNavigate();

  const {
    actions,
    description,
    href,
    image,
    imageButton,
    isActive,
    linesForTitle = 1,
    onNavigate,
    previewShape,
    title,
  } = props;

  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const showCardBody = isNonNullish(title) || isNonNullish(description);
  const [, startTransition] = useTransition();

  const doNavigation = useCallback(() => {
    if (href) {
      // If there's not a link element but there is an href, we need to call navigate explicitly
      onNavigate?.();
      startTransition(() => navigate(href));
    }
  }, [href, navigate, onNavigate]);

  return (
    <Card
      data-test="content-card"
      isActive={isActive}
      onPress={() => {
        doNavigation();
      }}
      orientation="vertical"
    >
      {/* 
        The order of these children (Body, Actions, Preview) is intentional to maintain the proper 
        tab order.

        The layout is managed with a grid which will order the elements in the expected way 
        (Preview, Body, Actions) visually regardless of their order in the DOM.
      */}
      {showCardBody ?
        <CardBody className={sprinkles({ textAlign: 'center' })}>
          {title ?
            <CardTitle lines={linesForTitle}>
              <Link
                href={href}
                onPress={() => {
                  if (href) {
                    onNavigate?.();
                  }
                }}
                ref={linkRef}
                underline="none"
              >
                {title}
              </Link>
            </CardTitle>
          : null}
          {description ?
            <CardSubtitle lines={2}>{description}</CardSubtitle>
          : null}
        </CardBody>
      : null}
      {actions ?
        <Group>{actions}</Group>
      : null}
      <CardPreview shape={previewShape}>
        {image}
        <CardPreviewOverlayButtonContainer>
          {imageButton}
        </CardPreviewOverlayButtonContainer>
      </CardPreview>
    </Card>
  );
}
