import { type IconProps, Icon } from './icon.js';

export function Gear(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Gear" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M19.64 12.98a7.59 7.59 0 0 0 0-1.96l2.17-1.65a.5.5 0 0 0 .12-.64l-2.05-3.46c-.13-.22-.4-.3-.63-.22l-2.56 1a7.56 7.56 0 0 0-1.74-.98l-.39-2.65a.5.5 0 0 0-.5-.42H9.95a.5.5 0 0 0-.5.42l-.4 2.65c-.63.25-1.2.59-1.74.98l-2.56-1a.5.5 0 0 0-.62.22L2.07 8.73a.48.48 0 0 0 .12.64l2.17 1.65a7.72 7.72 0 0 0 0 1.96L2.2 14.63a.5.5 0 0 0-.12.64l2.06 3.46c.12.22.4.3.62.22l2.56-1c.54.4 1.11.73 1.74.98l.4 2.65a.5.5 0 0 0 .5.42h4.1a.5.5 0 0 0 .51-.42l.4-2.65c.62-.25 1.2-.59 1.73-.98l2.56 1c.24.09.5 0 .63-.22l2.05-3.46a.5.5 0 0 0-.12-.64l-2.17-1.65ZM12 15.5A3.55 3.55 0 0 1 8.4 12c0-1.93 1.62-3.5 3.6-3.5 1.99 0 3.6 1.57 3.6 3.5S14 15.5 12 15.5Z" />
      </svg>
    </Icon>
  );
}
