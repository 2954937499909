import type {
  CardImageProps,
  CardProps,
} from '@iheartradio/web.accomplice/card';
import {
  Card,
  CardBody,
  CardImage,
  CardPreview,
  CardSubtitle,
  CardTitle,
} from '@iheartradio/web.accomplice/card';
import { Link } from '@iheartradio/web.accomplice/link';
import { useNavigate } from '@remix-run/react';
import {
  type HTMLAttributeAnchorTarget,
  type ReactNode,
  useCallback,
  useRef,
  useTransition,
} from 'react';
import { isNonNullish } from 'remeda';
import type { SetOptional } from 'type-fest';

export type GenreCardProps = Omit<CardProps, 'isActive' | 'onPress'> & {
  description?: string;
  href?: string;
  image: ReactNode;
  linkTarget?: HTMLAttributeAnchorTarget;
  onNavigate?: () => void;
  title?: string;
};

/**
 * The `<GenreCard />` component is used to display Genre content in a list.
 */
export function GenreCard(props: GenreCardProps) {
  const { description, href, image, onNavigate, linkTarget, title } = props;
  const [, startTransition] = useTransition();

  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const navigate = useNavigate();

  const showCardBody = isNonNullish(title) || isNonNullish(description);

  const doNavigation = useCallback(() => {
    if (href) {
      // If there's not a link element but there is an href, we need to call navigate explicitly
      onNavigate?.();
      startTransition(() => navigate(href));
    }
  }, [href, navigate, onNavigate]);

  return (
    <Card
      data-test="genre-card"
      onPress={() => {
        doNavigation();
      }}
      orientation="vertical"
    >
      {showCardBody ?
        <CardBody>
          <CardTitle lines={2}>
            <Link
              href={href}
              onPress={() => {
                if (href) {
                  onNavigate?.();
                }
              }}
              ref={linkRef}
              target={linkTarget}
              underline="none"
            >
              {title}
            </Link>
          </CardTitle>
          {description ?
            <CardSubtitle lines={2}>{description}</CardSubtitle>
          : null}
        </CardBody>
      : null}
      <CardPreview shape="square">{image}</CardPreview>
    </Card>
  );
}

export function GenreCardImage({
  src,
  ...props
}: SetOptional<CardImageProps, 'src'>) {
  if (!src) {
    return null;
  }

  return (
    <CardImage aspectRatio="16 / 9" placeholder={false} src={src} {...props} />
  );
}
