import { Gear, UserSettings } from '@iheartradio/web.accomplice/icons';
import { NavigationItem } from '@iheartradio/web.accomplice/navigation';
import type { User } from '@iheartradio/web.config';
import { NavLink } from '@remix-run/react';
import { $path } from 'remix-routes';

export function LoginSettingsNavItem({
  user,
  onClick,
}: {
  user: User | null;
  onClick: () => void;
}) {
  return !user || user?.isAnonymous ?
      <NavigationItem asChild>
        <NavLink
          data-test="login"
          onClick={onClick}
          to={$path('/login/:action?', { action: 'auth' })}
        >
          <UserSettings />
          Log In
        </NavLink>
      </NavigationItem>
    : <NavigationItem asChild>
        <NavLink data-test="settings" target="_blank" to={$path('/settings')}>
          <Gear />
          Settings
        </NavLink>
      </NavigationItem>;
}
