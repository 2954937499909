import { Box } from '@iheartradio/web.accomplice/box';
import type { ButtonProps } from '@iheartradio/web.accomplice/button';
import { Button } from '@iheartradio/web.accomplice/button';
import { Grid } from '@iheartradio/web.accomplice/grid';
import {
  VolumeHigh,
  VolumeLow,
  VolumeOff,
} from '@iheartradio/web.accomplice/icons';
import { type SliderProps, Slider } from '@iheartradio/web.accomplice/slider';
import { Tooltip, TooltipTrigger } from '@iheartradio/web.accomplice/tooltip';

interface PlayerVolumeProps {
  isDisabled?: SliderProps['isDisabled'];
  onChangeEnd?: SliderProps['onChangeEnd'];
  onMute?: ButtonProps['onPress'];
  muted?: boolean;
  volume: number;
}

// NOTE: This should be moved back to the component library once all the needed components have been refactored
export function PlayerVolume(props: PlayerVolumeProps) {
  const { isDisabled, muted, volume, onMute, onChangeEnd } = props;

  return (
    <Box
      display={{
        'container-xsmall': 'none',
        'container-large': 'grid',
      }}
      gridArea="volume"
      width="100%"
    >
      <Grid
        alignItems="center"
        columnGap="$4"
        data-test="player-volumez"
        gridTemplateColumns="min-content 1fr"
        padding="$0 $8"
        width="16rem"
      >
        <TooltipTrigger closeDelay={100} delay={250}>
          <Button
            color="white"
            kind="tertiary"
            onPress={volume > 0 ? onMute : undefined}
            size="icon"
          >
            {muted || volume === 0 ?
              <VolumeOff size={32} />
            : null}
            {!muted && volume > 0 && volume <= 50 && <VolumeLow size={32} />}
            {!muted && volume > 50 && <VolumeHigh size={32} />}
          </Button>
          <Tooltip placement="top">Mute / Unmute</Tooltip>
        </TooltipTrigger>
        <Slider
          aria-label="Adjust Volume"
          defaultValue={volume}
          isDisabled={isDisabled}
          maxValue={100}
          minValue={0}
          onChangeEnd={onChangeEnd}
        />
      </Grid>
    </Box>
  );
}
