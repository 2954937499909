import type {
  CardImageProps,
  CardProps,
} from '@iheartradio/web.accomplice/card';
import {
  Card,
  CardBody,
  CardImage,
  CardPreview,
  CardPreviewOverlayButtonContainer,
  CardSubtitle,
  CardTitle,
} from '@iheartradio/web.accomplice/card';
import { Link } from '@iheartradio/web.accomplice/link';
import { useNavigate } from '@remix-run/react';
import { type ReactNode, useCallback, useTransition } from 'react';
import { isNullish } from 'remeda';
import type { SetOptional } from 'type-fest';

export type FeatureCardProps = Omit<CardProps, 'onPress'> & {
  href?: string;
  title?: ReactNode;
  description?: ReactNode;
  image?: ReactNode;
  imageButton?: ReactNode;
  onNavigate?: () => void;
};

export function FeatureCard(props: FeatureCardProps) {
  const { isActive, href, image, imageButton, description, title, onNavigate } =
    props;

  const navigate = useNavigate();
  const [, startTransition] = useTransition();

  const doNavigation = useCallback(() => {
    if (href) {
      // If there's not a link element but there is an href, we need to call navigate explicitly
      onNavigate?.();
      startTransition(() => navigate(href));
    }
  }, [href, navigate, onNavigate]);

  return (
    <Card
      data-test="feature-card"
      isActive={isActive}
      onPress={() => {
        doNavigation();
      }}
      orientation="horizontal"
    >
      <CardBody>
        {title ?
          <CardTitle lines={2}>
            <Link
              href={href}
              onPress={() => {
                if (href) {
                  onNavigate?.();
                }
              }}
              underline="none"
            >
              {title}
            </Link>
          </CardTitle>
        : null}
        {description ?
          <CardSubtitle
            lines={{
              default: 2,
              xsmall: 2,
              small: 3,
              shmedium: 2,
              medium: 2,
              large: 3,
              xlarge: 3,
            }}
          >
            {description}
          </CardSubtitle>
        : null}
      </CardBody>
      <CardPreview>
        {image}
        <CardPreviewOverlayButtonContainer>
          {imageButton}
        </CardPreviewOverlayButtonContainer>
      </CardPreview>
    </Card>
  );
}

export function FeatureCardImage({
  src,
  ...props
}: SetOptional<CardImageProps, 'src'>) {
  if (isNullish(src)) {
    return null;
  }

  return (
    <CardImage
      {...props}
      aspectRatio="1 / 1"
      placeholder={false}
      src={src}
      style={{
        width: '100%',
        maxWidth: '100%',
      }}
    />
  );
}
