import {
  breakpoints,
  lightDark,
  sprinkles,
  vars,
} from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import type {
  CardImageProps,
  CardPreviewProps,
  CardProps,
  CardTitleProps,
} from '@iheartradio/web.accomplice/card';
import {
  Card,
  CardBody,
  CardImage,
  CardPreview,
  CardPreviewOverlayButtonContainer,
  CardSubtitle,
  CardTitle,
} from '@iheartradio/web.accomplice/card';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Group } from '@iheartradio/web.accomplice/group';
import { useMediaQuery } from '@iheartradio/web.accomplice/hooks';
import { Link } from '@iheartradio/web.accomplice/link';
import { type TextProps, Text } from '@iheartradio/web.accomplice/text';
import { useNavigate } from '@remix-run/react';
import {
  type ReactNode,
  useCallback,
  useRef,
  useState,
  useTransition,
} from 'react';
import { isNonNullish, isNullish } from 'remeda';
import type { SetOptional } from 'type-fest';

export function ContentCardImage({
  src,
  ...props
}: SetOptional<CardImageProps, 'src'>) {
  if (isNullish(src)) {
    return null;
  }

  return (
    <CardImage {...props} aspectRatio="1 / 1" placeholder={false} src={src} />
  );
}

function NumberRank({ rank, focus }: { rank: number; focus: boolean }) {
  const isLargeBreakpoint = useMediaQuery(breakpoints.large);

  const numberStyles: TextProps['css'] = {
    fontSize: { mobile: '5rem', shmedium: '7rem', large: '9rem' },
    lineHeight: 1,
    color:
      isLargeBreakpoint && focus ?
        lightDark(vars.color.red600, vars.color.red300)
      : lightDark(vars.color.gray100, vars.color.brandBlack),
    fontWeight: '600',
    userSelect: 'none',
    // @ts-expect-error This isn't supported on the type level but functions as expected
    WebkitTextStrokeWidth: isLargeBreakpoint && focus ? '0' : vars.stroke[1],
    // @ts-expect-error: unable to type WebkitTextStrokeColor
    WebkitTextStrokeColor: lightDark(vars.color.red600, vars.color.red300),
    cursor: 'pointer',
    bottom: 'anchor(bottom)',
    position: 'absolute',
    transition: 'all ease 300ms',
    textShadow: vars.shadow.elevation1,
  };

  return rank === 10 ?
      <>
        <Text
          aria-hidden="true"
          as="p"
          css={{
            ...numberStyles,
            marginLeft: {
              mobile: '-1.6rem',
              shmedium: '-2rem',
              large: '-2.3rem',
            },
          }}
          kind="h3"
          positionAnchor="--image"
        >
          1
        </Text>
        <Text
          aria-hidden="true"
          as="p"
          css={{
            ...numberStyles,
            marginRight: {
              mobile: '-0.4rem',
              shmedium: '-1rem',
              large: '-1.5rem',
            },
            marginLeft: { medium: '0.2rem', large: '0.5rem' },
          }}
          kind="h3"
          positionAnchor="--image"
        >
          0
        </Text>
      </>
    : <Text
        aria-hidden="true"
        as="p"
        css={{
          ...numberStyles,
          marginRight: { mobile: '-0.4rem', large: '-1.2rem' },
        }}
        kind="h3"
        positionAnchor="--image"
      >
        {rank}
      </Text>;
}

export type RankedContentCardProps = Omit<CardProps, 'onPress'> & {
  actions?: ReactNode;
  description?: ReactNode;
  href?: string;
  image?: ReactNode;
  imageButton?: ReactNode;
  linesForTitle?: CardTitleProps['lines'];
  onNavigate?: () => void;
  previewShape?: CardPreviewProps['shape'];
  title?: ReactNode;
  rank: number;
};

export function RankedContentCard(props: RankedContentCardProps) {
  const navigate = useNavigate();

  const {
    actions,
    description,
    href,
    image,
    imageButton,
    isActive,
    linesForTitle = 1,
    onNavigate,
    previewShape,
    title,
    rank,
  } = props;

  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const showCardBody = isNonNullish(title) || isNonNullish(description);
  const [focus, setFocus] = useState(false);

  const [, startTransition] = useTransition();

  const doNavigation = useCallback(() => {
    if (href) {
      // If there's not a link element but there is an href, we need to call navigate explicitly
      onNavigate?.();
      startTransition(() => navigate(href));
    }
  }, [href, navigate, onNavigate]);

  return (
    <Flex
      alignItems="center"
      cursor="pointer"
      data-test="ranked-content-card"
      height="100%"
      onClick={() => {
        doNavigation();
      }}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
      paddingBottom={vars.space[2]}
      paddingRight={vars.space[2]}
      paddingTop={vars.space[2]}
      position="relative"
    >
      <Box
        height={{ mobile: '5rem', shmedium: '7rem', large: '8rem' }}
        width={{ mobile: '2.9rem', shmedium: '3.9rem', large: '5.2rem' }}
      >
        <NumberRank {...{ rank, focus }} />
      </Box>
      <Card
        data-test="content-card"
        isActive={isActive}
        isHovered={focus}
        onPress={() => {
          doNavigation();
        }}
        orientation="vertical"
      >
        {showCardBody ?
          <CardBody className={sprinkles({ textAlign: 'center' })}>
            {title ?
              <CardTitle lines={linesForTitle}>
                <Link
                  href={href}
                  onPress={() => {
                    if (href) {
                      onNavigate?.();
                    }
                  }}
                  ref={linkRef}
                  underline="none"
                >
                  {title}
                </Link>
              </CardTitle>
            : null}
            {description ?
              <CardSubtitle lines={2}>{description}</CardSubtitle>
            : null}
          </CardBody>
        : null}
        {actions ?
          <Group>{actions}</Group>
        : null}
        <CardPreview shape={previewShape}>
          {image}
          <CardPreviewOverlayButtonContainer>
            {imageButton}
          </CardPreviewOverlayButtonContainer>
        </CardPreview>
      </Card>
    </Flex>
  );
}
