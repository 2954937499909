import { Player } from '@iheartradio/web.companion';
import { isNull } from '@iheartradio/web.utilities';
import { Link as RouterLink } from '@remix-run/react';

import { useIsTouch } from '~app/hooks/use-is-touch';
import {
  buildArtistTopSongsUrl,
  buildArtistUrl,
  buildSongUrl,
} from '~app/utilities/urls';

import { AddToPlaylist } from '../controls/add-to-playlist';
import { playback } from '../playback';

export function TopSongsMetadata() {
  const metadata = playback.useMetadata();
  const { station } = playback.useState();
  const { isTouch } = useIsTouch();

  if (isNull(station) || isNull(metadata)) {
    return null;
  }

  const { artistId, artistName, id, subtitle, title } = metadata.data;

  const artistUrl =
    artistId && artistName ?
      buildArtistUrl({ name: artistName, id: artistId })
    : undefined;

  return (
    <>
      <Player.MetadataText>
        <Player.Subtitle>
          {!isTouch && artistId ?
            <Player.Link
              as={RouterLink}
              data-test="subtitle-link"
              to={buildArtistTopSongsUrl({
                id: artistId,
                name: artistName,
              })}
              underline="hover"
            >
              {subtitle}
            </Player.Link>
          : subtitle}
        </Player.Subtitle>
        <Player.Title>
          {!isTouch && id && artistId && artistName ?
            <Player.Link
              as={RouterLink}
              data-test="title-link"
              to={buildSongUrl({
                artist: { id: artistId, name: artistName },
                track: { id, name: title ?? '' },
              })}
              underline="hover"
            >
              {title}
            </Player.Link>
          : title}
        </Player.Title>
        <Player.Description>
          {!isTouch && artistId && artistUrl ?
            <Player.Link
              as={RouterLink}
              data-test="description-link"
              to={artistUrl}
              underline="hover"
            >
              {artistName}
            </Player.Link>
          : artistName}
        </Player.Description>
      </Player.MetadataText>
      {Number(id) > 0 && <AddToPlaylist />}
    </>
  );
}
