import {
  type Dispatch,
  type MutableRefObject,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import type { ImageProps } from './image.js';

declare global {
  interface EventTarget {
    height: number;
    width: number;
  }
}

type Img = JSX.IntrinsicElements['img'];

export type UseImageProps = Pick<Img, 'src'>;

type UseImageState = {
  height: number;
  width: number;
  status: 'pending' | 'loaded' | 'failed';
};

type UseImageAction =
  | {
      type: 'LOADED';
    }
  | {
      type: 'SUCCEEDED';
      payload: {
        height: UseImageState['height'];
        width: UseImageState['width'];
      };
    }
  | {
      type: 'FAILED';
    };

const useImageReducer = (
  state: UseImageState,
  action: UseImageAction,
): UseImageState => {
  switch (action.type) {
    case 'LOADED': {
      return {
        ...state,
        status: 'loaded',
      };
    }
    case 'SUCCEEDED': {
      return {
        ...state,
        status: 'loaded',
        width: action.payload.width,
        height: action.payload.height,
      };
    }
    case 'FAILED': {
      return {
        ...state,
        status: 'failed',
      };
    }
    default: {
      throw new TypeError('Unknown useImageReducer action type');
    }
  }
};

// Make these handlers into factory functions so that `useCallback` is not needed in the body
// of the hook
const onErrorFactory = (dispatch: Dispatch<UseImageAction>) => () =>
  dispatch({ type: 'FAILED' });

const onLoadFactory =
  (
    dispatch: Dispatch<UseImageAction>,
    // pass in the `.current` value of the ref into the factory function so that...
    current: HTMLImageElement | null,
    getImageDimensions: boolean,
  ) =>
  (event: Event) => {
    // ... we can be sure the currentTarget of the event is in fact the correct `img` element
    if (event.currentTarget && event.currentTarget === current) {
      // If we need to extract the image dimensions, dispatch 'SUCCEEDED' with the target height/width
      if (getImageDimensions) {
        dispatch({
          type: 'SUCCEEDED',
          payload: {
            height: event.currentTarget.height,
            width: event.currentTarget.width,
          },
        });
      } else {
        // Just dispatch 'LOADED'
        dispatch({ type: 'LOADED' });
      }
    }
  };

export const useImage = (
  ref: MutableRefObject<HTMLImageElement | null>,
  props: Pick<ImageProps, 'height' | 'width'>,
) => {
  const { width = 0, height = 0 } = props;

  const [state, dispatch] = useReducer(useImageReducer, {
    // If only one of width or height are passed in, set them equal
    width,
    height,
    status: 'pending',
  });

  useEffect(() => {
    const { current } = ref;
    const onload = onLoadFactory(dispatch, current, !(width > 0 || height > 0));
    const onerror = onErrorFactory(dispatch);

    if (current) {
      current.addEventListener('load', onload);
      current.addEventListener('error', onerror);

      return () => {
        current.removeEventListener('load', onload);
        current.removeEventListener('error', onerror);
      };
    }
  }, [ref, width, height]);

  const useImageProps = useMemo(
    () => ({
      status: state.status,
      height: state.height,
      width: state.width,
    }),
    [state.height, state.width, state.status],
  );

  return useImageProps;
};
