const abortListenerFactory = (controller: AbortController) => {
  return () => controller.abort();
};

export function fetchWithTimeout(
  url: string | URL,
  ms: number,
  { signal, ...options }: RequestInit = {},
) {
  const controller = new AbortController();
  const abortHandler = abortListenerFactory(controller);

  const fetchEvent = fetch(url, { signal: controller.signal, ...options });
  if (signal) {
    signal.addEventListener('abort', abortHandler);
  }
  const timeout = setTimeout(abortHandler, ms);

  return fetchEvent.finally(() => {
    clearTimeout(timeout);
    if (signal) {
      signal.removeEventListener('abort', abortHandler);
    }
  });
}
