import { type IconProps, Icon } from './icon.js';

export function VolumeOff(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Volume Off" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M3.63 5.04a.996.996 0 0 1 0-1.41c.4-.39 1.03-.39 1.42 0l15.31 15.32a.996.996 0 1 1-1.41 1.41l-1.34-1.34c-.67.54-1.42.98-2.22 1.31-.66.27-1.39-.19-1.39-.91 0-.39.22-.77.58-.92a6.83 6.83 0 0 0 1.6-.91L12 13.41v4.17c0 .89-1.08 1.34-1.71.71L7 15H4c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h3l.29-.3-3.66-3.66Zm14.96 9.3c.26-.73.41-1.52.41-2.34 0-2.94-1.82-5.46-4.39-6.5-.36-.14-.61-.47-.61-.85v-.19c0-.63.63-1.09 1.22-.86A8.995 8.995 0 0 1 21 12c0 1.39-.32 2.7-.88 3.87l-1.53-1.53Zm-8.3-8.63-.17.17L12 7.76V6.41c0-.89-1.08-1.33-1.71-.7ZM14 7.97A4.5 4.5 0 0 1 16.5 12c0 .044-.003.089-.008.133-.003.036-.008.072-.012.107L14 9.76V7.97Z" />
      </svg>
    </Icon>
  );
}
