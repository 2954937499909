import { MediaServerURL } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { isBlank, slugify } from '@iheartradio/web.utilities';
import { $path } from 'remix-routes';

import { playlistAnalyticsData } from '~app/analytics/playlist-analytics-helper';
import { useItemSelected } from '~app/analytics/use-item-selected';
import type { FavoritesStation, MadeForYouRecs } from '~app/api/types';
import {
  CardCarousel,
  useCarouselSlidesContext,
} from '~app/components/card-carousel';
import {
  FeatureCard,
  FeatureCardImage,
} from '~app/components/feature-card/feature-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import { useUser } from '~app/contexts/user';
import { Play } from '~app/playback/controls/play';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';
import { isAnonymousUser, isPremiumUser } from '~app/utilities/user';

const sectionTitle = 'Made For You';

function PlaylistSlide({
  playlist,
  loadingStrategy = 'eager',
  index,
  sectionPosition,
  pageName,
}: {
  playlist: MadeForYouRecs[number];
  loadingStrategy?: HTMLImageElement['loading'];
  index: number;
  sectionPosition: number;
  pageName: string;
}) {
  const isMobile = useIsMobile();
  const { onItemSelected } = useItemSelected();

  const user = useUser();
  const isPremium = isPremiumUser(user);

  const type =
    isPremium ?
      Playback.StationType.Playlist
    : Playback.StationType.PlaylistRadio;

  const { playing } = Play.usePlaylistPlay({
    context: 0,
    id: `${Number(playlist?.userId)}::${playlist?.id}`,
    type,
  });

  const playlistAssets = playlistAnalyticsData({
    isCurated: playlist.curated,
    playlistId: playlist.id,
    profileId: user?.profileId?.toString() ?? '',
    playlistUserId: playlist.userId,
    playlistName: playlist.name,
    userType: user?.subscription?.type,
    isAnonymous: isAnonymousUser(user) ?? true,
    playlistType: type,
  });

  return (
    <CardCarousel.Slide>
      <FeatureCard
        description={playlist.description}
        href={buildPlaylistUrl({ ...playlist })}
        image={
          <FeatureCardImage
            alt={`Image for playlist: ${playlist.name}`}
            decoding="auto"
            loading={loadingStrategy}
            src={playlist.urls?.image}
            width={isMobile ? 105 : 120}
          />
        }
        imageButton={
          <Play.Playlist
            context={0}
            id={`${Number(playlist?.userId)}::${playlist?.id}`}
            shuffle={false}
            size={48}
            type={type}
          />
        }
        isActive={playing}
        onNavigate={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: playlistAssets.asset,
            },
          });
        }}
        title={playlist.name}
      />
    </CardCarousel.Slide>
  );
}

export function FavoritesSlide({
  mfr,
  index,
  sectionPosition,
  pageName,
}: {
  mfr: FavoritesStation;
  index: number;
  sectionPosition: number;
  pageName: string;
}) {
  const { onItemSelected } = useItemSelected();
  const isMobile = useIsMobile();

  const { description, name, seedProfileId } = mfr;

  const { playing } = Play.useFavoritesPlay({
    context: 0,
    id: seedProfileId,
  });

  return (
    <CardCarousel.Slide>
      <FeatureCard
        description={description}
        href={$path('/favorites/:userId?', { userId: seedProfileId })}
        image={
          <FeatureCardImage
            alt="Image for My Favorites Radio"
            src={MediaServerURL.fromCatalog({
              type: 'favorites',
              id: String(seedProfileId),
            })}
            width={isMobile ? 105 : 120}
          />
        }
        imageButton={
          <Play.Favorites context={0} id={seedProfileId} size={48} />
        }
        isActive={playing}
        onNavigate={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            assets: {
              asset: {
                id: `${Playback.StationType.Favorites}|${seedProfileId}`,
                name: name ?? '',
              },
            },
            sectionPosition,
            itemPosition: index,
          });
        }}
        title="My Favorites Radio"
      />
    </CardCarousel.Slide>
  );
}

export function MadeForYouCarousel({
  myFavoritesRadio,
  playlistRecs,
  color,
  pageName,
  sectionPosition,
}: {
  myFavoritesRadio: FavoritesStation | null;
  playlistRecs: MadeForYouRecs;
  color?: string;
  pageName: string;
  sectionPosition: number;
}) {
  if (isBlank(playlistRecs) || playlistRecs.length < 2) {
    return null;
  }

  return (
    <CardCarousel color={color} kind="featured" title={sectionTitle}>
      <MadeForYouSlides
        mfr={myFavoritesRadio}
        pageName={pageName}
        playlists={playlistRecs}
        sectionPosition={sectionPosition}
      />
    </CardCarousel>
  );
}

function MadeForYouSlides({
  mfr,
  playlists,
  pageName,
  sectionPosition,
}: {
  mfr: FavoritesStation | null;
  playlists: MadeForYouRecs;
  pageName: string;
  sectionPosition: number;
}) {
  const [initialSlides] = useCarouselSlidesContext();

  return (
    <>
      {mfr ?
        <FavoritesSlide
          index={0}
          mfr={mfr}
          pageName={pageName}
          sectionPosition={sectionPosition}
        />
      : null}
      {playlists.map((playlist, index) => (
        <PlaylistSlide
          index={mfr ? index + 1 : index}
          key={playlist.id}
          loadingStrategy={index < initialSlides ? 'eager' : 'lazy'}
          pageName={pageName}
          playlist={playlist}
          sectionPosition={sectionPosition}
        />
      ))}
    </>
  );
}
