import { MediaServerURL } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { isBlank, isNotBlank, slugify } from '@iheartradio/web.utilities';

import { useItemSelected } from '~app/analytics/use-item-selected';
import {
  CardCarousel,
  useCarouselSlidesContext,
} from '~app/components/card-carousel';
import {
  ContentCard,
  ContentCardImage,
} from '~app/components/content-card/content-card';
import { FollowButton } from '~app/components/follow-button';
import { useIsMobile } from '~app/contexts/is-mobile';
import { useFollowUnfollow } from '~app/hooks/use-follow-unfollow/use-follow-unfollow';
import { Play } from '~app/playback/controls/play';
import type { HomeClientLoaderData } from '~app/routes/api.v1.home';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildArtistUrl } from '~app/utilities/urls';

export function Slide({
  artist,
  index,
  loadingStrategy = 'eager',
  pageName,
  section,
  sectionPosition,
}: {
  artist: HomeClientLoaderData['recommendedArtists'][number];
  index: number;
  loadingStrategy?: HTMLImageElement['loading'];
  pageName: string;
  section: string;
  sectionPosition: number;
}) {
  const { onItemSelected } = useItemSelected();
  const isMobile = useIsMobile();
  const { follow, unfollow, isFollowing } = useFollowUnfollow({
    contentId: String(artist?.id),
    contentType: Playback.StationType.Artist,
    contentName: artist?.artistName ?? '',
    section: slugify(section),
    context: 'carousel',
    // removed `isFollowingStation` so that the hook will determine the follow status itself
  });

  const { playing } = Play.useArtistPlay({
    context: 0,
    id: Number(artist?.id),
  });

  if (isBlank(artist)) {
    return null;
  }

  const artistUrl =
    artist.id && artist.artistName ?
      buildArtistUrl({
        id: artist.id,
        name: artist.artistName,
      })
    : undefined;

  return (
    <CardCarousel.Slide key={artist.id}>
      <ContentCard
        actions={
          <FollowButton
            isFollowing={isFollowing}
            onFollow={() => {
              follow();
            }}
            onUnfollow={() => {
              unfollow();
            }}
          />
        }
        href={artistUrl}
        image={
          <ContentCardImage
            alt={artist.artistName ?? ''}
            decoding="auto"
            loading={loadingStrategy}
            // 'Circle-ify' the image using MediaServer in order to remove the extra box, which
            // was causing some rendering issues
            src={MediaServerURL.fromCatalog({
              type: 'artist',
              id: String(artist.id),
            })
              .quality(isMobile ? 40 : 75)
              .run('circle')}
            width={isMobile ? 75 : 150}
          />
        }
        imageButton={
          <Play.Artist context={0} id={Number(artist?.id)} size={48} />
        }
        isActive={playing}
        linesForTitle={2}
        onNavigate={() => {
          onItemSelected({
            pageName,
            section: slugify(section),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${Playback.StationType.Artist}|${artist?.id}`,
                name: artist?.artistName ?? '',
              },
            },
          });
        }}
        previewShape="circle"
        title={artist.artistName}
      />
    </CardCarousel.Slide>
  );
}

Slide.displayName = 'RecommendedArtistsCarouselSlide';

export function RecommendedArtistsCarousel({
  artists,
  pageName,
  sectionPosition,
  sectionTitle,
}: {
  artists: HomeClientLoaderData['recommendedArtists'];
  pageName: string;
  sectionPosition: number;
  sectionTitle: string;
}) {
  return isNotBlank(artists) ?
      <CardCarousel kind="content" title={sectionTitle}>
        <RecommendedArtistsSlides
          artists={artists}
          pageName={pageName}
          sectionPosition={sectionPosition}
          sectionTitle={sectionTitle}
        />
      </CardCarousel>
    : null;
}

function RecommendedArtistsSlides({
  artists,
  pageName,
  sectionPosition,
  sectionTitle,
}: {
  artists: HomeClientLoaderData['recommendedArtists'];
  pageName: string;
  sectionPosition: number;
  sectionTitle: string;
}) {
  const [initialSlides] = useCarouselSlidesContext();

  return artists.map((artist, index) => (
    <Slide
      artist={artist}
      index={index}
      key={artist.id}
      loadingStrategy={index < initialSlides ? 'eager' : 'lazy'}
      pageName={pageName}
      section={sectionTitle}
      sectionPosition={sectionPosition}
    />
  ));
}

export function RecommendedArtistsCarouselFallback({
  sectionTitle,
}: {
  sectionTitle: string;
}) {
  return (
    <CardCarousel kind="content" title={sectionTitle}>
      <div style={{ height: '286px' }} />
    </CardCarousel>
  );
}
