import { useToast } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { useCallback } from 'react';

import { AuthenticateCTANotification } from '~app/components/cta/authenticate-cta';
import { useUser } from '~app/contexts/user';
import { REG_GATE_TRIGGER_TYPES } from '~app/utilities/constants';

import { usePlay } from './use-play';

export type UseFavoritesPlayProps = Omit<
  Playback.FavoritesStation,
  'type' | 'targeting' | 'seed'
>;

export function useFavoritesPlay(props: UseFavoritesPlayProps) {
  const { isAnonymous } = useUser();

  const play = usePlay({ ...props, type: Playback.StationType.Favorites });

  const toast = useToast();

  const onClick = useCallback(async () => {
    if (!isAnonymous) {
      play.onClick();
      return;
    }

    toast(dismiss => (
      <AuthenticateCTANotification
        dismiss={dismiss}
        trigger={REG_GATE_TRIGGER_TYPES.MADE_FOR_YOU}
      >
        Log in to save content and enjoy your personalized playlists
      </AuthenticateCTANotification>
    ));
  }, [isAnonymous, play, toast]);

  return { ...play, onClick } as const;
}
