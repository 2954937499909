import { MediaServerURL } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { useTheme } from '@iheartradio/web.remix-shared/react/theme.js';
import { isBlank, isNotBlank, slugify } from '@iheartradio/web.utilities';
import { useFetcher } from '@remix-run/react';

import { useItemSelected } from '~app/analytics/use-item-selected';
import {
  CardCarousel,
  useCarouselSlidesContext,
} from '~app/components/card-carousel';
import {
  ContentCard,
  ContentCardImage,
} from '~app/components/content-card/content-card';
import { MarketsDropdown } from '~app/components/markets-dropdown';
import { useIsMobile } from '~app/contexts/is-mobile';
import { SelectInteractionProvider } from '~app/contexts/select-interaction';
import { useRootLoaderData } from '~app/hooks/use-root-loader-data';
import { SET_MARKET_FETCHER_KEY } from '~app/hooks/use-set-market-id';
import { Play } from '~app/playback/controls/play';
import type { LivePlayProps } from '~app/playback/controls/play/live-play';
import type { LiveStationRec } from '~app/services/types';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildLiveUrl } from '~app/utilities/urls';

const sectionTitle = 'Live Radio in';

function Slide({
  liveStation,
  sectionPosition,
  index,
  pageName,
  loadingStrategy = 'eager',
}: {
  liveStation: LiveStationRec;
  sectionPosition: number;
  index: number;
  pageName: string;
  loadingStrategy?: HTMLImageElement['loading'];
}) {
  const { onItemSelected } = useItemSelected();
  const isMobile = useIsMobile();
  const theme = useTheme();

  const playProps: LivePlayProps = {
    context: 0,
    id: Number(liveStation?.id),
  };

  const { playing } = Play.useLivePlay(playProps);

  if (isBlank(liveStation)) {
    return null;
  }

  const liveUrl = liveStation.id ? buildLiveUrl({ ...liveStation }) : undefined;

  return (
    <CardCarousel.Slide key={liveStation.id}>
      <ContentCard
        description={liveStation.description}
        href={liveUrl}
        image={
          <ContentCardImage
            alt={liveStation.name ?? ''}
            decoding="auto"
            // If this is the first slide in the carousel, set `fetchpriority` to high
            // in order to speed up FCP/LCP, since this carousel is 'above the fold'
            {...(index === 0 ? { fetchpriority: 'high' } : {})}
            loading={loadingStrategy}
            // Add the correct background color by using MediaServer functions
            src={MediaServerURL.fromURL(liveStation.logo)
              .quality(isMobile ? 40 : 50)
              .new()
              .flood(theme === 'dark' ? '#27292D' : '#FFF')
              .swap()
              .merge('over')}
            width={isMobile ? 75 : 150}
          />
        }
        imageButton={<Play.Live {...playProps} size={48} />}
        isActive={playing}
        onNavigate={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${Playback.StationType.Live}|${liveStation?.id}`,
                name: liveStation?.name ?? '',
                type: Playback.StationType.Live,
              },
            },
          });
        }}
        title={liveStation.name}
      />
    </CardCarousel.Slide>
  );
}

Slide.displayName = 'LocalLiveStationsCarouselSlide';

export function LocalLiveStationsCarousel({
  liveStations,
  sectionPosition,
  pageName,
}: {
  liveStations: LiveStationRec[];
  sectionPosition: number;
  pageName: string;
}) {
  const { market } = useRootLoaderData();
  const fetcher = useFetcher({ key: SET_MARKET_FETCHER_KEY });

  return isNotBlank(liveStations) ?
      <SelectInteractionProvider>
        <CardCarousel
          key={market.marketId}
          kind="content"
          loading={fetcher.state === 'loading'}
          title={<MarketsDropdown sectionTitle={sectionTitle} />}
        >
          <LiveStationSlides
            liveStations={liveStations}
            pageName={pageName}
            sectionPosition={sectionPosition}
          />
        </CardCarousel>
      </SelectInteractionProvider>
    : null;
}

// Uses the value from `useCarouselSlidesContext`
// to determine whether to set `loading` prop (for the card image)
// to `eager` or `lazy`.
//
// We want `eager` if the slide is initally visible, so that the page will not try and render a
// potential LCP (Largest Contentful Paint) image asynchronously (which is bad for Lighthouse score)
function LiveStationSlides({
  liveStations,
  sectionPosition,
  pageName,
}: {
  liveStations: LiveStationRec[];
  sectionPosition: number;
  pageName: string;
}) {
  const [initialSlides] = useCarouselSlidesContext();

  return liveStations.map((liveStation, index) => (
    <Slide
      index={index}
      key={liveStation.id}
      liveStation={liveStation}
      // If `index` is less than the max viewable slides for a carousel, set `loading` to eager
      // so that rendering will complete quicker
      loadingStrategy={index < initialSlides ? 'eager' : 'lazy'}
      pageName={pageName}
      sectionPosition={sectionPosition}
    />
  ));
}
