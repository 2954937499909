import { getCssText } from '@iheartradio/web.companion';
import { proxyImage } from '@iheartradio/web.remix-shared/client';
import { RemixBrowser } from '@remix-run/react';
import {
  type ReactNode,
  startTransition,
  StrictMode,
  useCallback,
  useState,
} from 'react';
import { hydrateRoot } from 'react-dom/client';

import ClientStyleContext from './contexts/client-style';

// if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
//   const { default: whyDidYouRender } = await import(
//     '@welldone-software/why-did-you-render'
//   );
//   whyDidYouRender(React);
// }

interface ClientCacheProviderProps {
  children: ReactNode;
}

function ClientCacheProvider({ children }: ClientCacheProviderProps) {
  const [sheet, setSheet] = useState(getCssText());

  const reset = useCallback(() => {
    setSheet(getCssText());
  }, []);

  return (
    <ClientStyleContext.Provider value={{ reset, sheet }}>
      {children}
    </ClientStyleContext.Provider>
  );
}

const registerServiceWorker = async () => {
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  navigator.serviceWorker.register(
    `/service-worker.js?prefersDark=${prefersDark}`,
  );
  window.document.addEventListener('keydown', async event => {
    if (event.metaKey && event.key === 'k') {
      const { active } = await navigator.serviceWorker.ready;
      active?.postMessage('purge');
    }
  });
};

if ('serviceWorker' in navigator) {
  if (window.document.readyState === 'complete') {
    registerServiceWorker();
  } else {
    window.addEventListener('load', () => {
      registerServiceWorker();
    });
  }
}

// Execute the ImageProxy shim from `@iheartradio/web.remix-shared/client`
proxyImage();

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <ClientCacheProvider>
        <RemixBrowser />
      </ClientCacheProvider>
    </StrictMode>,
  );
});
