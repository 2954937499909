import { useFetcher } from '@remix-run/react';
import { useCallback, useState } from 'react';
import { $path } from 'remix-routes';

import type { SetMarketAction } from '~app/routes/api.v1.set-market';

export const SET_MARKET_FETCHER_KEY = 'set-market';

/**
 * Like a `useState()` hook but is server state aware: meaning it submits an action using a fetcher to the backend.
 */
export const useSetMarketId = (initialMarketId: number) => {
  const [marketState, setMarketState] = useState({
    initial: initialMarketId,
    current: initialMarketId,
  });
  const fetcher = useFetcher<SetMarketAction>({ key: SET_MARKET_FETCHER_KEY });

  const marketId = marketState.current;

  return [
    /**
     * The current marketId.
     */
    marketId,

    /**
     * An action-aware handler. Sets the client-side and server-side market state.
     */
    useCallback(
      (newMarketId: number) => {
        setMarketState(previous => ({ ...previous, current: newMarketId }));

        fetcher.submit(
          { marketId: newMarketId },
          {
            method: 'POST',
            action: $path('/api/v1/set-market'),
            encType: 'application/json',
          },
        );
      },
      [fetcher],
    ),
  ] as const;
};
