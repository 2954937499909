import { vars } from '@iheartradio/web.accomplice';
import { Notification } from '@iheartradio/web.accomplice/notification';
import type { ReactNode } from 'react';
import { $path } from 'remix-routes';

import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import { Routes } from '~app/utilities/constants';

export function AuthenticateCTANotification(props: {
  dismiss?: () => void;
  trigger: string;
  children?: ReactNode;
}) {
  const {
    dismiss,
    children = 'Log in to save your favorites and access Your Library',
    trigger = 'cta',
  } = props;
  const { onAnalyticsRegGateOpen, onAnalyticsRegGateExit } = useRegGateEvent();

  return (
    <Notification
      actions={[
        {
          kind: 'tertiary',
          size: { mobile: 'small', medium: 'large' },
          color: 'gray',
          textColor: vars.color.gray600,
          href: $path(Routes.Login, { action: 'auth' }),
          onPress: () => onAnalyticsRegGateOpen(trigger),
          content: 'Log in',
        },
        {
          kind: 'tertiary',
          size: { xsmall: 'small', medium: 'large' },
          color: 'gray',
          textColor: vars.color.gray600,
          href: $path(Routes.SignUp),
          onPress: () => onAnalyticsRegGateOpen(trigger),
          content: 'Sign up',
        },
      ]}
      data-test="authenticate-cta-notification"
      kind="info"
      onDismiss={() => {
        onAnalyticsRegGateExit(trigger);
        dismiss?.();
      }}
    >
      {children}
    </Notification>
  );
}
