import { memoize } from '@iheartradio/web.utilities';

import { amp } from './amp-client';
import type { Market } from './types';

export const getMarkets = memoize(async () => {
  const { body } = await amp.api.v2.content.getMarkets({
    query: { limit: 10_000, sortBy: 'stateName' },
    throwOnErrorStatus: false,
  });
  return body?.hits ?? [];
});

export const getMarketLabel = (market: Market) =>
  `${market?.city}, ${market?.stateAbbreviation}, ${market?.countryAbbreviation}`;

export const getMarketsById = memoize(async (markets?: Market[]) => {
  const allMarkets = markets ?? (await getMarkets());

  return allMarkets.reduce<Record<string, Market>>(
    (result, market) => ({
      ...result,
      [String(market.marketId)]: market,
    }),
    {},
  );
});

export const getOrderedMarkets = memoize(async () => {
  const allMarkets = await getMarkets();

  return allMarkets.sort(
    (marketA, marketB) =>
      marketB.countryAbbreviation.localeCompare(marketA.countryAbbreviation) ||
      marketA.stateAbbreviation.localeCompare(marketB.stateAbbreviation) ||
      marketA.city.localeCompare(marketB.city),
  );
});
