import { useMemo } from 'react';
import { isNullish } from 'remeda';

import { useUser } from '~app/contexts/user';

export const useDisplayAd = (breakpoint: boolean = true) => {
  const user = useUser();

  const display = useMemo(() => {
    return !(
      !isNullish(user) &&
      (user.subscription?.type === 'PREMIUM' ||
        user.subscription?.type === 'PLUS')
    );
  }, [user]);

  return {
    display: display && breakpoint,
  };
};
