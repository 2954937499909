import { fetchWithTimeout } from '@iheartradio/web.utilities';
import { $path } from 'remix-routes';

import { fetchClientData } from '~app/utilities/fetch-client-data';

/**
 * 2024/11/30
 *
 * This method was refactored to fetch from a resource route instead of directly from AMP so that
 * 404 errors could be suppressed in the console in order to raise the Lighthouse score of the site.
 *
 * The resource route returns a 200 even if the station is not being followed
 */

export async function isStationFollowed({
  contentId,
  type,
  userId,
}: {
  contentId: string | number;
  type: string;
  userId?: string | number;
}) {
  try {
    const fetchUrl = `${$path('/api/v1/following/:contentType/:contentId', { contentType: type, contentId })}${userId ? `?userId=${String(userId)}` : ''}`;
    return await fetchClientData(fetchWithTimeout(fetchUrl, 500), {
      followed: false,
      followable: false,
    });
  } catch {
    return { followed: false, followable: false };
  }
}
