import { MediaServerURL } from '@iheartradio/web.assets';
import { isBlank, slugify } from '@iheartradio/web.utilities';

import { useItemSelected } from '~app/analytics/use-item-selected';
import {
  CardCarousel,
  useCarouselSlidesContext,
} from '~app/components/card-carousel';
import {
  GenreCard,
  GenreCardImage,
} from '~app/components/genre-card/genre-card';
import { useConfig } from '~app/contexts/config';
import { useIsMobile } from '~app/contexts/is-mobile';
import { AnalyticsContext } from '~app/utilities/constants';

import type { HomeLoaderData } from '../_home';

export function PopularNewsCarousel(props: {
  popularNewsItems: HomeLoaderData['popularNewsItems'];
  sectionPosition: number;
  pageName: string;
}) {
  const { popularNewsItems } = props;
  const { onItemSelected } = useItemSelected();

  if (isBlank(popularNewsItems)) {
    return null;
  }

  const sectionTitle = 'Popular News';

  return (
    <CardCarousel kind="news" title={sectionTitle}>
      <PopularNewsSlides
        {...props}
        onItemSelected={onItemSelected}
        sectionTitle={sectionTitle}
      />
    </CardCarousel>
  );
}

function PopularNewsSlides(props: {
  onItemSelected: ReturnType<typeof useItemSelected>['onItemSelected'];
  pageName: string;
  popularNewsItems: HomeLoaderData['popularNewsItems'];
  sectionPosition: number;
  sectionTitle: string;
}) {
  const {
    popularNewsItems,
    sectionPosition,
    pageName,
    onItemSelected,
    sectionTitle,
  } = props;

  const [initialSlides] = useCarouselSlidesContext();
  const config = useConfig();
  const isMobile = useIsMobile();

  return popularNewsItems
    .filter(item => item.type === 'PUBLISHING')
    .map(({ data: newsItem }, index) => (
      <CardCarousel.Slide key={newsItem.id}>
        <GenreCard
          href={`${config.urls.content}${newsItem.slug}`}
          image={
            <GenreCardImage
              alt={newsItem.summary?.title}
              decoding="auto"
              loading={index < initialSlides ? 'eager' : 'lazy'}
              src={MediaServerURL.fromURL(newsItem.summary?.image)
                .ratio(16, 9)
                .quality(isMobile ? 40 : 75)}
              width={isMobile ? 150 : 300}
            />
          }
          linkTarget="_blank"
          onNavigate={() => {
            if (pageName) {
              onItemSelected({
                pageName,
                context: AnalyticsContext.Carousel,
                sectionPosition,
                itemPosition: index,
                section: slugify(sectionTitle),
                assets: {
                  asset: {
                    id: `news|${newsItem.id}`,
                    name: newsItem.summary?.title,
                  },
                },
              });
            }
          }}
          title={newsItem.summary?.title}
        />
      </CardCarousel.Slide>
    ));
}
