import { Playback } from '@iheartradio/web.playback';
import { slugify } from '@iheartradio/web.utilities';

import { playlistAnalyticsData } from '~app/analytics/playlist-analytics-helper';
import { useItemSelected } from '~app/analytics/use-item-selected';
import type { PlaylistRecs } from '~app/api/types';
import {
  CardCarousel,
  useCarouselSlidesContext,
} from '~app/components/card-carousel';
import {
  ContentCard,
  ContentCardImage,
} from '~app/components/content-card/content-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import { useUser } from '~app/contexts/user';
import { Play } from '~app/playback/controls/play';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';
import { isAnonymousUser, isPremiumUser } from '~app/utilities/user';

const sectionTitle = 'Recommended Playlists';

function Slide({
  loadingStrategy = 'eager',
  playlist,
  pageName,
  index,
  sectionPosition,
}: {
  loadingStrategy?: HTMLImageElement['loading'];
  playlist: PlaylistRecs[number];
  pageName: string;
  index: number;
  sectionPosition: number;
}) {
  const isMobile = useIsMobile();
  const { onItemSelected } = useItemSelected();
  const user = useUser();
  const isPremium = isPremiumUser(user);

  const type =
    isPremium ?
      Playback.StationType.Playlist
    : Playback.StationType.PlaylistRadio;

  const playlistAssets = playlistAnalyticsData({
    isCurated: playlist.curated,
    playlistId: playlist.id,
    profileId: user?.profileId?.toString() ?? '',
    playlistUserId: playlist.userId,
    playlistName: playlist.name,
    userType: user?.subscription?.type,
    isAnonymous: isAnonymousUser(user) ?? true,
    playlistType: type,
  });

  const { playing } = Play.usePlaylistPlay({
    context: 0,
    id: `${Number(playlist.userId)}::${playlist.id}`,
    type,
  });

  return (
    <CardCarousel.Slide key={playlist.reportingKey}>
      <ContentCard
        description={playlist.description}
        href={buildPlaylistUrl({ ...playlist })}
        image={
          <ContentCardImage
            alt={playlist.name}
            decoding="auto"
            loading={loadingStrategy}
            src={playlist.urls?.image}
            width={isMobile ? 75 : 150}
          />
        }
        imageButton={
          <Play.Playlist
            context={0}
            id={`${Number(playlist.userId)}::${playlist.id}`}
            shuffle={false}
            size={48}
            type={type}
          />
        }
        isActive={playing}
        onNavigate={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: playlistAssets.asset,
            },
          });
        }}
        title={playlist.name}
      />
    </CardCarousel.Slide>
  );
}

export function RecommendedPlaylistsCarousel({
  pageName,
  playlists,
  sectionPosition,
}: {
  pageName: string;
  playlists: PlaylistRecs;
  sectionPosition: number;
}) {
  if (playlists === undefined || playlists?.length < 5) {
    return null;
  }

  return (
    <CardCarousel kind="content" title={sectionTitle}>
      <RecommendedPlaylistsSlides
        pageName={pageName}
        playlists={playlists}
        sectionPosition={sectionPosition}
      />
    </CardCarousel>
  );
}

function RecommendedPlaylistsSlides({
  pageName,
  playlists,
  sectionPosition,
}: {
  pageName: string;
  playlists: PlaylistRecs;
  sectionPosition: number;
}) {
  const [initialSlides] = useCarouselSlidesContext();

  return playlists.map((playlist, index) => (
    <Slide
      index={index}
      key={playlist.id}
      loadingStrategy={index < initialSlides ? 'eager' : 'lazy'}
      pageName={pageName}
      playlist={playlist}
      sectionPosition={sectionPosition}
    />
  ));
}
