import { assignInlineVars } from '@vanilla-extract/dynamic';
import {
  type ComponentProps,
  type ForwardedRef,
  type PropsWithChildren,
  forwardRef,
} from 'react';

import type { RainbowSprinkles } from '../../rainbow-sprinkles.css.js';
import {
  inlineAdHeight,
  inlineAdStyles,
  inlineAdWidth,
} from './inline-ad.css.js';

export type InlineAdProps = PropsWithChildren<ComponentProps<'div'>> &
  Pick<RainbowSprinkles, 'width' | 'height'>;

function _InlineAdContainer(
  { width, height, children, ...props }: InlineAdProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      className={inlineAdStyles}
      ref={ref}
      style={assignInlineVars({
        [inlineAdHeight]: `${height}px`,
        [inlineAdWidth]: `${width}px`,
      })}
      {...props}
    >
      {children}
    </div>
  );
}

export const InlineAdContainer = forwardRef(_InlineAdContainer);
