import { Slot } from '@radix-ui/react-slot';
import type { LinkDOMProps } from '@react-types/shared';
import { clsx } from 'clsx/lite';
import {
  type ForwardedRef,
  type PropsWithChildren,
  forwardRef,
  useRef,
} from 'react';
import { type AriaButtonProps, useButton } from 'react-aria';
import { Button as Primitive, Link } from 'react-aria-components';
import type { NavigateOptions } from 'react-router';
import { isNonNullish, omit } from 'remeda';

import { useMapMediaProp } from '../../hooks/use-mapmedia-prop.js';
import type { RainbowSprinkles } from '../../rainbow-sprinkles.css.js';
import type { DOMAttributes } from '../../types.js';
import type { MediaValue } from '../../types/index.js';
import { getRecipeStyleProps } from '../../utilities/get-recipe-style-props.js';
import {
  type ButtonVariants,
  buttonRecipe,
  hideOutlineOnFocusStyle,
} from './button.css.js';
import { type Size, sizes } from './size.js';

export type ButtonProps = ButtonVariants &
  DOMAttributes &
  Omit<LinkDOMProps, 'routerOptions'> &
  AriaButtonProps & {
    //  TODO: Do not use "as" prop.. should be removed when menu/drawer component gets updated to use RAC
    as?: React.ElementType;
    asChild?: boolean;
    css?: RainbowSprinkles;
    continuePropagation?: boolean;
    hideOutlineOnFocus?: boolean;
    size?: Size | MediaValue<Size>;
    title?: string;
    textColor?: string;
    routerOptions?: NavigateOptions;
  };

function Button(
  {
    as,
    asChild,
    children,
    className,
    css,
    color = 'red',
    textColor,
    inverted = false,
    isDisabled = false,
    continuePropagation = false,
    hideOutlineOnFocus,
    kind = 'primary',
    size = 'small',
    ...props
  }: PropsWithChildren<ButtonProps>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  if (isNonNullish(props.onClick) && isNonNullish(props.onPress)) {
    throw new Error(
      'The web.accomplice Button accepts either native events (onClick, onKeyDown, onPointerDown, etc.) or RAC events (onPress, etc.), not both.',
    );
  }

  const _internalButtonRef = useRef<HTMLButtonElement | null>(null);
  const _internalAnchorRef = useRef<HTMLAnchorElement | null>(null);
  const buttonRef = ref ?? _internalButtonRef;

  const { buttonProps } = useButton(
    { ...props, preventFocusOnPress: true },
    buttonRef,
  );

  const sizeCSS = useMapMediaProp(
    size,
    (value: keyof typeof sizes) => sizes[value],
  );

  const rs = getRecipeStyleProps(
    buttonRecipe,
    {
      ...omit(props, ['onPress']),
      inverted,
      color,
      kind,
    },
    { ...sizeCSS, ...css },
  );

  const classNames = clsx(
    rs.className,
    className,
    hideOutlineOnFocus && hideOutlineOnFocusStyle,
  );
  const styles = { ...rs.style, color: textColor };

  const Component = as ?? (asChild ? Slot : Primitive);
  const disabledComponentProps =
    Component !== Primitive ? { disabled: isDisabled } : { isDisabled };

  return props.href ?
      <Link
        className={classNames}
        isDisabled={isDisabled}
        ref={_internalAnchorRef}
        style={styles}
        {...rs.otherProps}
        {...props}
      >
        {children}
      </Link>
    : <Component
        className={classNames}
        ref={buttonRef}
        style={styles}
        {...disabledComponentProps}
        {...rs.otherProps}
        {...props}
        {...buttonProps}
        {...{
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
            // Prevent the event from bubbling
            if (!continuePropagation) {
              event.stopPropagation();
            }

            if (props?.onClick) {
              props?.onClick?.(event);
            } else {
              buttonProps.onClick(event);
            }
          },
          onKeyDown: (event: React.KeyboardEvent<HTMLButtonElement>) => {
            // Prevent the event from bubbling
            if (!continuePropagation) {
              event.stopPropagation();
            }

            if (props?.onKeyDown) {
              props?.onKeyDown?.(event);
            } else {
              buttonProps.onKeyDown(event);
            }
          },

          onPointerDown: (event: React.PointerEvent<HTMLButtonElement>) => {
            // Prevent the event from bubbling
            if (!continuePropagation) {
              event.stopPropagation();
            }

            if (props?.onPointerDown) {
              props?.onPointerDown?.(event);
            } else {
              buttonProps.onPointerDown(event);
            }
          },
        }}
      >
        {children}
      </Component>;
}

export const _Button = forwardRef(Button);
export { _Button as Button };
