import { Button } from '@iheartradio/web.accomplice/button';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Spacer } from '@iheartradio/web.accomplice/spacer';
import { Text } from '@iheartradio/web.accomplice/text';
import { isBlank } from '@iheartradio/web.utilities';
import { isEmpty } from 'remeda';

import { useConfig } from '~app/contexts/config';

import type { HomeServerLoaderData } from '../../.server/loader';

export type CampaignData = HomeServerLoaderData['internalCampaign'];

export const HomeHero = ({ campaignData }: { campaignData: CampaignData }) => {
  const config = useConfig();
  if (
    !config.features?.showHomepageHero ||
    isBlank(campaignData) ||
    isEmpty(campaignData)
  ) {
    return <Spacer data-test="empty-spacer" />;
  }

  const { image = '', isInternal, link, title } = campaignData;
  // Get this info from campaignData after api/data is updated
  const buttonText = 'Listen Now';
  const textButtonColor = 'dark';

  return (
    <Flex
      alignItems="center"
      backgroundImage={`url('${image}')`}
      backgroundPosition="right center"
      backgroundSize="cover"
      data-test="home-hero"
      height={{ mobile: '194px', large: '172px', xlarge: '274px' }}
      padding="$32"
      position="relative"
      width="100%"
    >
      <Flex
        direction="column"
        gap="$12"
        width={{ mobile: '50%', small: '60%' }}
      >
        <Text
          as="h1"
          css={{
            color: textButtonColor === 'dark' ? '$gray600' : '$brandWhite',
          }}
          kind={{ mobile: 'h4', large: 'h3' }}
          lines={2}
        >
          {title}
        </Text>
        <Flex>
          <Button
            color={textButtonColor === 'dark' ? 'gray' : 'white'}
            kind="secondary"
            size="small"
            {...(isInternal ? null : { target: '_blank' })}
            href={link ?? ''}
          >
            {buttonText}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
