import { type IconProps, Icon } from './icon.js';

export function Library(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Library" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M7.85714 3c-.23669 0-.42857.19188-.42857.42857s.19188.42857.42857.42857h7.71426c.2367 0 .4286-.19188.4286-.42857S15.8081 3 15.5714 3H7.85714ZM5.71439 6.427c.00087-.47339.38532-.85644.85871-.85557l10.2857.01883c.4734.00087.8564.38533.8555.85871-.0008.47339-.3853.85644-.8587.85558L6.56996 7.28571c-.47338-.00087-.85644-.38532-.85557-.85871Z" />
        <path
          clipRule="evenodd"
          d="M4.95702 21H18.472c.5281 0 .9566-.3329.9566-.744V9.74398c0-.41112-.4285-.74398-.9566-.74398H4.95702C4.42844 9 4 9.33286 4 9.74398V20.256c0 .4111.42844.744.95702.744Zm5.83888-8.9388c0-.5061.4123-.9183.9184-.9183s.9184.4122.9184.9183v2.0204h2.0204c.506 0 .9183.4123.9183.9184s-.4123.9184-.9183.9184h-2.0204v2.0204c0 .5061-.4123.9183-.9184.9183s-.9184-.4122-.9184-.9183v-2.0204H8.77551c-.50608 0-.91837-.4123-.91837-.9184s.41229-.9184.91837-.9184h2.02039v-2.0204Z"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  );
}
