import { Playback } from '@iheartradio/web.playback';
import { isBlank, slugify } from '@iheartradio/web.utilities';

import { useItemSelected } from '~app/analytics/use-item-selected';
import type { FeaturedPlaylistsResult } from '~app/api/types';
import {
  CardCarousel,
  useCarouselSlidesContext,
} from '~app/components/card-carousel';
import {
  ContentCard,
  ContentCardImage,
} from '~app/components/content-card/content-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import { useUser } from '~app/contexts/user';
import { useGetImageDominantColor } from '~app/hooks/use-get-image-dominant-color';
import { Play } from '~app/playback/controls/play';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';
import { isPremiumUser } from '~app/utilities/user';

const sectionTitle = 'Featured Playlists';

function Slide({
  loadingStrategy = 'eager',
  playlist,
  pageName,
  index,
  sectionPosition,
}: {
  loadingStrategy?: HTMLImageElement['loading'];
  playlist: FeaturedPlaylistsResult[number];
  pageName: string;
  index: number;
  sectionPosition: number;
}) {
  const isMobile = useIsMobile();
  const { onItemSelected } = useItemSelected();
  const user = useUser();
  const isPremium = isPremiumUser(user);

  const [userId, id] = String(playlist.catalog?.id).split('::');
  const type =
    isPremium ?
      Playback.StationType.Playlist
    : Playback.StationType.PlaylistRadio;

  const { playing } = Play.usePlaylistPlay({
    context: 0,
    id: `${Number(userId)}::${id}`,
    type,
  });

  return (
    <CardCarousel.Slide key={playlist.id ?? playlist?.catalog?.id}>
      <ContentCard
        description={playlist.subtitle ?? ''}
        href={buildPlaylistUrl({
          name: playlist.title ?? '',
          userId,
          id,
        })}
        image={
          <ContentCardImage
            alt={playlist.title ?? ''}
            decoding="auto"
            loading={loadingStrategy}
            src={playlist?.img_uri || undefined}
            width={isMobile ? 75 : 150}
          />
        }
        imageButton={
          <Play.Playlist
            context={0}
            id={`${Number(userId)}::${id}`}
            shuffle={false}
            size={48}
            type={type}
          />
        }
        isActive={playing}
        onNavigate={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${type}|${playlist.catalog?.id}`,
                name: playlist.title ?? '',
              },
            },
          });
        }}
        title={playlist.title ?? ''}
      />
    </CardCarousel.Slide>
  );
}

export function FeaturedPlaylistsCarousel({
  pageName,
  playlists,
  sectionPosition,
}: {
  pageName: string;
  playlists: FeaturedPlaylistsResult | [];
  sectionPosition: number;
}) {
  const carouselBackgroundColor = useGetImageDominantColor(
    playlists?.[0]?.img_uri,
  );

  if (isBlank(playlists) || playlists.length === 0) {
    return null;
  }

  return (
    <CardCarousel
      color={carouselBackgroundColor}
      description="Explore latest releases, picked by iHeart for our dear listeners"
      kind="content"
      title={sectionTitle}
    >
      <FeaturedPlaylistsSlides
        pageName={pageName}
        playlists={playlists}
        sectionPosition={sectionPosition}
      />
    </CardCarousel>
  );
}

function FeaturedPlaylistsSlides({
  pageName,
  playlists,
  sectionPosition,
}: {
  pageName: string;
  playlists: FeaturedPlaylistsResult;
  sectionPosition: number;
}) {
  const [initialSlides] = useCarouselSlidesContext();

  return playlists.map((playlist, index) => (
    <Slide
      index={index}
      key={playlist.id}
      loadingStrategy={index < initialSlides ? 'eager' : 'lazy'}
      pageName={pageName}
      playlist={playlist}
      sectionPosition={sectionPosition}
    />
  ));
}
