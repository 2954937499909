import {
  type ImgHTMLAttributes,
  type ReactElement,
  isValidElement,
  useId,
  useRef,
} from 'react';
import { clamp } from 'remeda';
import srcset from 'srcset';

import { MediaServerURL } from './media-server-url.js';
import { type GravityRegion, GravityRegions } from './types.js';
import { useImage } from './use-image.js';

type DefaultImageAttributes = ImgHTMLAttributes<HTMLImageElement>;

type ImageFormat = 'webp' | 'png' | 'jpeg' | 'gif';

export type ImageProps = {
  /**
   * The alt text for the image.
   */
  alt: string;
  /**
   * The aspect ratio of the image
   */
  aspectRatio?: `${number} / ${number}`;
  /**
   * Classnames generated from Vanilla Extract styling
   */
  classNames?: {
    wrapper?: string;
    placeholder?: string;
    image?: string;
  };
  /**
   * Sprinkles styles
   */
  style?: Record<string, string>;
  /**
   * The gravity to apply to the image
   */
  gravity?: GravityRegion;
  /**
   * The height of the image. Passed to the underlying `height` attribute.
   */
  height?: number;
  /**
   * The height of the image. Passed to the underlying `width` attribute.
   */
  width?: number;
  fetchpriority?: DefaultImageAttributes['fetchPriority'];
  /**
   * The format of the image file to load.
   * @default webp
   */
  format?: ImageFormat;
  /**
   * Define custom loading functionality for the image.
   * @default defaultImageLoader
   */
  resolver?: ImageResolverFunction;
  decoding?: DefaultImageAttributes['decoding'];
  /**
   * Define the `loading` attribute for the image
   * @default "lazy"
   */
  loading?: DefaultImageAttributes['loading'];
  /**
   * Define custom loading functionality for the placeholder image.
   * @default defaultPlaceholderLoader
   */
  placeholderResolver?: ImageResolverFunction;
  /**
   * Whether or not to render a placeholder image.
   * - If `false` or `undefined`, no placeholder will be used.
   * - If `true`, the placeholder will be created using the `placeholderLoader` function.
   * - If a `string`, the value will be used as the placeholder image source, bypassing the `placeholderLoader` function.
   * - If a `ReactElement`, the value will be rendered as the placeholder.
   *
   * Defaults to `true`
   */
  placeholder?: boolean | string | ReactElement;
  /**
   * The source of the image.
   */
  src: string | URL | MediaServerURL;
  /**
   * The quality to use for the image. Specifying this value will add a `quality` parameter to the MediaServerURL.
   * @default 75
   */
  quality?: number;
  /**
   * The widths to define for the image. This is used to generate the `srcset` attribute for various widths. Must define `sizes` if this is defined.
   */
  widths?: Array<number>;
  /**
   * The densities to define for the image. This is used to generate the `srcset` attribute for
   * various pixel densities.
   * @default [1, 2]
   */
  densities?: Array<number>;
  /**
   * The sizes to define for the image. This is used to generate the `sizes` attribute. Must be
   * defined if `widths` is defined.
   */
  sizes?: DefaultImageAttributes['sizes'];
};

export type ImageResolverFunctionArgs = {
  aspectRatio: ImageProps['aspectRatio'];
  density: Exclude<ImageProps['densities'], undefined>[number];
  gravity: Exclude<ImageProps['gravity'], undefined>;
  src: ImageProps['src'];
  height: ImageProps['height'];
  width: ImageProps['width'];
  quality: Exclude<ImageProps['quality'], undefined>;
  format: Exclude<ImageProps['format'], undefined>;
};

export type ImageResolverFunction = (args: ImageResolverFunctionArgs) => string;

function isNil(x: unknown): x is null | undefined {
  return x === null || x === undefined;
}

function isNumber(x: unknown): x is number {
  return !isNil(x) && !Number.isNaN(x);
}

function wholeNumber(x: unknown) {
  return isNumber(x) ? Number(Math.floor(x).toFixed(0)) : 0;
}

export function defaultResolver(options: ImageResolverFunctionArgs) {
  const {
    aspectRatio = '1 / 1',
    density = 1,
    format,
    gravity,
    height: _height,
    quality,
    src,
    width: _width,
  } = options;
  const url = MediaServerURL.fromURL(src);

  // If we pass in a MediaServerURL, we don't want to override the `format` and `quality` already set
  if (!url.hasOp('format')) {
    url.format(format);
  }

  if (!url.hasOp('quality')) {
    url.quality(quality);
  }
  if (!url.hasOp('gravity')) {
    url.gravity(gravity);
  }

  const width = wholeNumber(_width) * density;
  const height = wholeNumber(_height) * density;

  if (width > 0 || height > 0) {
    url.removeOps('fit');
    url.fit(width, height);
  }

  if (!url.hasOp('ratio')) {
    const [aspectWidth, aspectHeight] = aspectRatio
      .split('/')
      .reduce(
        (accumulator, value) => [...accumulator, value.trim()],
        [] as string[],
      );
    url.ratio(Number(aspectWidth), Number(aspectHeight));
  }

  return url.toString();
}

export function defaultPlaceholderResolver(options: ImageResolverFunctionArgs) {
  const { src, format, width } = options;
  return MediaServerURL.fromURL(src)
    .format(format)
    .quality(1)
    .fit(clamp((width ?? 200) * 0.5, { min: 1, max: 100 }), 0)
    .toString();
}

class ImageArgumentsError extends TypeError {
  constructor(message: string) {
    super(message);
  }
}

/**
 * A component for rendering images with support for responsive images, lazy loading, and
 * placeholders. This component is a wrapper around the native `<img>` element.
 */
export function Image(props: ImageProps): JSX.Element | null {
  const {
    alt,
    classNames,
    decoding = 'async',
    densities,
    format = 'webp',
    gravity = GravityRegions.Center,
    height: _height,
    resolver = defaultResolver,
    loading = 'lazy',
    placeholder = true,
    placeholderResolver = defaultPlaceholderResolver,
    quality = 75,
    sizes,
    style,
    src,
    widths,
    width: _width,
    ...restProps
  } = props;
  let { aspectRatio } = restProps;
  delete restProps.aspectRatio;

  const key = useId();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const { height, status, width } = useImage(imgRef, {
    height: _height,
    width: _width,
  });

  if (width && height && !aspectRatio) {
    aspectRatio = `${Number(Number(width / height).toFixed(2))} / ${height / height}`;
  }

  try {
    if (densities && densities.length > 0 && widths && widths.length > 0) {
      throw new ImageArgumentsError(
        'cannot specify both "densities" and "widths"',
      );
    }

    if (widths && widths.length > 0 && !sizes) {
      throw new ImageArgumentsError(
        'if declaring "widths", "sizes" must also be declared',
      );
    }

    const placeholderSource =
      placeholder && typeof placeholder === 'string' ? placeholder
      : placeholder === true ?
        placeholderResolver({
          aspectRatio,
          density: 1,
          gravity,
          width,
          height,
          src,
          format,
          quality,
        })
      : null;

    const usePlaceholder =
      placeholderSource !== null || isValidElement(placeholder);

    const imageSource = resolver({
      aspectRatio,
      width,
      format,
      gravity,
      quality,
      height,
      density: 1,
      src,
    });

    const sourceSetDefinitions =
      widths && widths.length > 0 ?
        [...widths, width].sort().map(width => {
          return {
            url: resolver({
              aspectRatio,
              density: 1,
              gravity,
              width,
              height: 0,
              src,
              format,
              quality,
            }),
            width,
          };
        })
      : (densities ?? [1, 2]).sort().map(density => {
          return {
            url: resolver({
              aspectRatio,
              density,
              gravity,
              width,
              src,
              height,
              format,
              quality,
            }),
            density,
          };
        });

    const sourceSet = srcset.stringify(sourceSetDefinitions);

    return usePlaceholder ?
        // Use `data-loaded` attribute to control the visibility/opacity of the placeholder and "real"
        // image with defined styles
        <div
          {...(classNames?.wrapper ? { className: classNames.wrapper } : {})}
          data-status={status}
          data-test="web.assets.image-wrapper"
          key={key}
          ref={wrapperRef}
          style={style}
        >
          {placeholderSource ?
            <img
              alt={`${alt} (thumbnail)`}
              {...(classNames?.placeholder ?
                { className: classNames.placeholder }
              : {})}
              data-test="web.assests.image-placeholder"
              // Set `loading="eager"` and `decoding="sync"` since we want this placeholder image to
              // be loaded and rendered ASAP
              decoding="sync"
              loading="eager"
              src={placeholderSource}
              style={{
                ...style,
                display: status !== 'loaded' ? 'block' : 'none',
              }}
            />
          : placeholder}
          <img
            decoding={decoding}
            loading={loading}
            {...restProps}
            alt={alt}
            {...(classNames?.image ? { className: classNames.image } : {})}
            data-test="web.assets.image"
            {...(height > 0 ? { height } : {})}
            ref={imgRef}
            sizes={sizes}
            src={imageSource}
            srcSet={sourceSet}
            style={{
              ...style,
              display: status === 'loaded' ? 'block' : 'none',
            }}
            {...(width > 0 ? { width } : {})}
          />
        </div>
      : <img
          decoding={decoding}
          key={key}
          loading={loading}
          {...restProps}
          alt={alt}
          className={classNames?.image ?? ''}
          data-test="web.assets.image"
          {...(height > 0 ? { height } : {})}
          ref={imgRef}
          sizes={sizes}
          src={imageSource}
          srcSet={sourceSet}
          style={style}
          {...(width > 0 ? { width } : {})}
        />;
  } catch (error: unknown) {
    if (error instanceof ImageArgumentsError) {
      throw new TypeError(error.message);
    }

    console.warn(
      error instanceof Error ? error.message : JSON.stringify(error),
    );
    return null;
  }
}
