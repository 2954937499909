/// <reference types="jwplayer" />

import {
  type SafeInterval,
  isNil,
  isUndefined,
} from '@iheartradio/web.utilities';
import { createEmitter } from '@iheartradio/web.utilities/create-emitter';
import type { Logger } from '@iheartradio/web.utilities/create-logger';
import { toJSON } from 'flatted';

import { PlayerError, PlayerErrorCode } from './player:error.js';
import { Playback } from './player:types.js';
import {
  processInStreamMetadata,
  setLiveStreamCompanion,
} from './utility:process-in-stream-metadata.js';

type JWPlayerEventsFactoryParams<Station extends Playback.Station> = {
  amp: Playback.Api;
  jwPlayer: jwplayer.JWPlayer;
  player: Playback.Player<Station>;
  logger: Logger;
  options: {
    adEnvironment?: string | null;
    methods: {
      load(state: Playback.PlayerState<Station> | null): void;
      setCurrentTrackMeta: ({
        streamId,
        stationMeta,
      }: {
        streamId: number;
        stationMeta: Readonly<Playback.QueueItem['meta']>;
      }) => Promise<void>;
      setStatus: (status: Playback.Status) => void;
      setAdsStatus: (status: Playback.AdPlayerStatus) => void;
      isAdBreak: () => boolean;
    };
  };
  SafeInterval: SafeInterval;
};

export const LIVE_META_INTERVAL_KEY = 'live-meta';

export const createPlayerEventsHandlers = <Station extends Playback.Station>({
  amp,
  player,
  jwPlayer,
  logger,
  options,
  SafeInterval,
}: JWPlayerEventsFactoryParams<Station>) => {
  const { methods } = options;
  const { isAdBreak, load, setStatus, setAdsStatus, setCurrentTrackMeta } =
    methods;

  const jwPlayerEventHandlers = createEmitter({
    adBreakEnd(ad: jwplayer.AdBreak<'adBreakEnd'>) {
      try {
        const tag = new URL(ad.tag);
        const type = tag.searchParams.get('type');
        const isPreroll =
          type ?
            type.includes(Playback.AdType.Preroll)
          : player.getAds().get('current')?.type;

        const adType =
          isPreroll ? Playback.AdType.Preroll : Playback.AdType.Midroll;

        player.adComplete(adType);
      } catch {
        player.adComplete(player.getAds().get('current')?.type);
      }
    },
    adClick() {
      setStatus(Playback.Status.Paused);
      setAdsStatus(Playback.AdPlayerStatus.Paused);
    },
    adError(data: jwplayer.AdErrorParam) {
      let tag: URL | undefined = undefined;
      try {
        tag = new URL(data.tag);
      } catch {
        const currentTag = player.getAds().get('current')?.tag;
        if (currentTag) {
          tag = new URL(currentTag);
        }
      }

      if (tag) {
        const type = tag.searchParams.get('type');
        const isPreroll =
          type ?
            type.includes(Playback.AdType.Preroll)
          : player.getAds().get('current')?.type;

        const { code, message } =
          isPreroll ? PlayerError.Preroll : PlayerError.Midroll;

        const error = PlayerError.new({
          code,
          message,
          data,
        });
        player.setError(error);
        player.adEnd();
      } else {
        const error = PlayerError.new({
          code: PlayerErrorCode.Generic,
        });
        player.setError(error);
        player.adEnd();
      }
    },
    adPlay() {
      setStatus(Playback.Status.Playing);
      setAdsStatus(Playback.AdPlayerStatus.Playing);
    },
    adRequest() {
      player.adRequest();
    },
    adSkipped(ad: jwplayer.AdProgressParam) {
      const tag = new URL(ad.tag);
      const type = tag.searchParams.get('type');
      const isPreroll =
        type ?
          type.includes(Playback.AdType.Preroll)
        : player.getAds().get('current')?.type;

      const adType =
        isPreroll ? Playback.AdType.Preroll : Playback.AdType.Midroll;

      player.adComplete(adType);
    },
    adStarted(ad: jwplayer.AdStartedParam) {
      player.adStart(ad);
    },
    adTime(time: jwplayer.AdTimeParam) {
      const { duration, position } = time;
      player.setTime({
        duration: Math.max(0, duration),
        position: Math.max(0, position),
      });
    },
    autostartNotAllowed(data: {
      code: 303_220;
      error: unknown;
      reason: 'autoplayDisabled';
      type: 'autostartNotAllowed';
    }) {
      const error = PlayerError.new({
        code: PlayerError.AutoplayBlocked.code,
        data,
      });

      player.setError(error);
    },
    beforeComplete() {
      player.midroll();
    },
    beforePlay() {
      player.preroll();
    },
    buffer() {
      setStatus(Playback.Status.Buffering);
    },
    error(data: jwplayer.ErrorParam) {
      const error = PlayerError.new({
        code: PlayerError.InternalPlayerError.code,
        data: toJSON(data),
      });

      player.setError(error);
    },
    idle() {
      setStatus(Playback.Status.Idle);
      setAdsStatus(Playback.AdPlayerStatus.Idle);
    },
    async metadataCueParsed(data: jwplayer.MetadataParam) {
      const { COMM, TXXX } = data.metadata;
      if (!isUndefined(TXXX) || !isUndefined(COMM)) {
        const { queue, index } = player.getState().deserialize();
        const stationMeta = queue[index].meta;

        if (isAdBreak()) {
          return;
        }

        // If we were able to successfully parse metadata from stream, then disable fallback
        SafeInterval.clear(LIVE_META_INTERVAL_KEY);

        const metadataOrCompanion = await processInStreamMetadata(
          data.metadata,
          amp,
          stationMeta,
          logger,
        );

        if (isNil(metadataOrCompanion)) {
          SafeInterval.set(
            LIVE_META_INTERVAL_KEY,
            () =>
              setCurrentTrackMeta({
                streamId: Number(queue[index].id),
                stationMeta,
              }),
            5000,
          );
        } else if (!isNil(metadataOrCompanion)) {
          SafeInterval.clear(LIVE_META_INTERVAL_KEY);

          // If it's metadata, then set the metadata on the player
          if (metadataOrCompanion.type === 'meta') {
            player.setMetadata(metadataOrCompanion.data);
          } else if (metadataOrCompanion.type === 'companion') {
            /*
             * Set the companions
             */
            for (const companionAd of metadataOrCompanion.data) {
              const {
                companion,
                delay,
                identifier = String(Date.now()),
                previousAdMarkers,
                offsetTimeEvents,
              } = companionAd;
              logger.info(`Setting Live Stream companion, ${identifier}`, {
                companion,
                delay,
                COMM,
                previousAdMarkers,
              });
              setLiveStreamCompanion<Station>({
                liveInStreamAdPayload: companion,
                player,
                logger,
                delay,
                identifier,
                offsetTimeEvents,
              });
            }
          }
        }
      }
    },
    pause() {
      setStatus(Playback.Status.Paused);
    },
    play() {
      setStatus(Playback.Status.Playing);
      setAdsStatus(Playback.AdPlayerStatus.Done);
    },
    playAttemptFailed(data: {
      error: unknown;
      item: unknown;
      playReason: string;
    }) {
      const error = PlayerError.new({
        code: PlayerError.PlayAttemptFailed.code,
        data,
      });

      player.setError(error);
    },
    ready() {
      const state = player.getState().deserialize();
      const { index, muted, queue, volume, time } = state;

      const item = queue[index];

      if (!isUndefined(item)) {
        queue[index] = { ...item, starttime: time.position };
        load(state);
      }

      // IHRWEB-20350 - if the stored state indicates that the player should be muted, we should
      // only call `jwplayer.setMute` and not `jwplayer.setVolume`. Calling `jwplayer.setVolume`
      // with a value other than 0 triggers another internal `setMute` with the state set to false.
      if (muted) {
        jwPlayer.setMute(muted);
      } else {
        jwPlayer.setVolume(volume);
      }
    },
    setupError(data: jwplayer.ErrorParam) {
      const error = PlayerError.new({
        code: PlayerError.InternalPlayerError.code,
        data,
      });

      player.setError(error);
    },
    time({ duration, position }: jwplayer.TimeParam) {
      player.setTime({ duration, position });
    },
  });

  const visibilitychangeHandler = () => {
    const state = player.getState().deserialize();
    const { station, queue, index, status } = state;
    const stationMeta = queue[index]?.meta;

    if (
      document.visibilityState === 'visible' &&
      station &&
      station.type === Playback.StationType.Live &&
      station.id &&
      stationMeta
    ) {
      // If not currently playing, set track meta immediately, then start interval
      if (status !== Playback.Status.Playing) {
        setCurrentTrackMeta({
          streamId: Number(station.id),
          stationMeta,
        });
      }
      SafeInterval.set(
        LIVE_META_INTERVAL_KEY,
        () =>
          setCurrentTrackMeta({
            streamId: Number(station.id),
            stationMeta,
          }),
        5000,
      );
    } else {
      SafeInterval.clear(LIVE_META_INTERVAL_KEY);
    }
  };

  return { jwPlayerEventHandlers, visibilitychangeHandler };
};

export type JWPlayerEventHandlers = ReturnType<
  typeof createPlayerEventsHandlers
>['jwPlayerEventHandlers'];
