import type { Dispatch, ReactNode, SetStateAction } from 'react';
import {
  createContext as _createContext,
  useContext as _useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isNullish } from 'remeda';

export function createStateContext<Value>(
  displayName: string,
  initialValue: Value,
) {
  const Context = _createContext<[Value, Dispatch<SetStateAction<Value>>]>([
    initialValue,
    () => {},
  ]);

  function Provider({
    children,
    value = initialValue,
  }: {
    children: ReactNode;
    value?: Value;
  }) {
    const [state, setState] = useState<Value>(value);

    useEffect(() => setState(value), [value]);

    return (
      <Context.Provider value={useMemo(() => [state, setState], [state])}>
        {children}
      </Context.Provider>
    );
  }

  Provider.displayName = `${displayName}.Provider`;

  function useContext<T = undefined>() {
    const context = _useContext(Context);
    if (isNullish(context)) {
      console.warn(
        `Attempted to use context "${displayName}" outside of context provider`,
      );
    }
    return context as [
      T extends undefined ? Value : T,
      Dispatch<SetStateAction<T extends undefined ? Value : T>>,
    ];
  }

  return {
    defaultValue: initialValue,
    Provider,
    useContext,
  } as const;
}
