import { type IconProps, Icon } from './icon.js';

export function Browse(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Browse" {...props}>
      <svg viewBox="0 0 24 24">
        <path
          clipRule="evenodd"
          d="M9.50675 17.1908c1.37395 0 2.66165-.3734 3.76935-1.0255l4.5773 5.4033c.4386.5177 1.2089.5776 1.7205.1339.5116-.4437.5709-1.2231.1323-1.7408l-4.5472-5.3678c1.1547-1.3357 1.8545-3.0844 1.8545-4.99849C17.0135 5.40058 13.6526 2 9.50675 2 5.36088 2 2 5.40058 2 9.59541c0 4.19479 3.36088 7.59539 7.50675 7.59539Zm0-2.9629c2.52855 0 4.57845-2.074 4.57845-4.63249 0-2.55845-2.0499-4.63249-4.57845-4.63249-2.52859 0-4.57842 2.07404-4.57842 4.63249 0 2.55849 2.04983 4.63249 4.57842 4.63249Z"
          fillRule="evenodd"
        />
        <path d="M17.3305 4.46933c-.3115-.52901-.6698-1.02491-1.0688-1.48146h5.0273c.3927 0 .7111.33164.7111.74073s-.3184.74073-.7111.74073h-3.9585Zm1.3628 4.44437c-.0332-.50528-.1032-1.00006-.2071-1.48145h2.8028c.3927 0 .7111.33163.7111.74073 0 .40909-.3184.74072-.7111.74072h-2.5957Zm-.2337 2.9629c-.1167.5104-.2716 1.0054-.4614 1.4815h3.2908c.3927 0 .7111-.3317.7111-.7407 0-.4091-.3184-.7408-.7111-.7408h-2.8294Z" />
      </svg>
    </Icon>
  );
}
