import { vars } from '@iheartradio/web.accomplice';
import { Notification } from '@iheartradio/web.accomplice/notification';
import type { ReactNode } from 'react';

import { useConfig } from '~app/contexts/config';

export function UpgradeCTANotification(props: {
  dismiss?: () => void;
  children?: ReactNode;
}) {
  const { dismiss, children = 'Want to listen to anything, anytime?' } = props;

  const config = useConfig();

  return (
    <Notification
      actions={[
        {
          kind: 'tertiary',
          size: { xsmall: 'small', medium: 'large' },
          color: 'gray',
          textColor: vars.color.gray600,
          href: `${config.urls.account}/subscribe`,
          content: 'Listen with iHeart All Access',
        },
      ]}
      data-test="upgrade-cta-notification"
      kind="info"
      onDismiss={dismiss}
    >
      {children}
    </Notification>
  );
}
